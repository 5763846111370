import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { addInventoryItemService } from '../services/addInventoryItemService';
import { AddInventoryItemSchema } from '../types/AddInventoryItemSchema';

const initialState: AddInventoryItemSchema = {
    addItemMode: false,
};

export const AddInventoryItemSlice = createSlice({
    name: 'AddInventoryItem',
    initialState,
    reducers: {
        setEditItemMode: (state, action: PayloadAction<boolean>) => {
            state.addItemMode = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addInventoryItemService.pending, (state) => {
                state.error = undefined;
                state.isLoading = true;
            })
            .addCase(addInventoryItemService.fulfilled, (state) => {
                state.isLoading = false;
                toast('Items added in Inventory');
            })
            .addCase(addInventoryItemService.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { actions: addInventoryItemActions } = AddInventoryItemSlice;
export const { reducer: addInventoryItemReducer } = AddInventoryItemSlice;
