import { Box, Skeleton } from '@mui/material';
import { memo } from 'react';

export const CatalogItemSkeleton = memo(() => {
    return (
        <Box display="flex" gap="16px" p="20px 0 16px">
            <Skeleton width="56px" height="56px" variant="rounded" />
            <Box flex="1">
                <Skeleton height="24px" sx={{ mb: '10px' }} />
                <Skeleton height="24px" sx={{ mb: '6px' }} />
                <Skeleton height="24px" sx={{ mb: '6px' }} />
                <Skeleton height="24px" />
            </Box>
            <Box p="9px">
                <Skeleton height="28px" width="28px" variant="rounded" />
            </Box>
        </Box>
    );
});
