import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { isArray } from 'shared/lib/lodash/lodash';
import { revertCatalogStores } from 'shared/ui/store/resetStoresAction';

import { addCatalogItemService } from '../services/addCatalogItemService';
import { AddCatalogItemSchema } from '../types/AddCatalogItemSchema';

const initialState: AddCatalogItemSchema = {
    addItemMode: false,
    showDialog: false,
    isLoading: false,
    addedItemsCount: 0,
};

export const AddCatalogItemSlice = createSlice({
    name: 'AddCatalogItem',
    initialState,
    reducers: {
        setEditItemMode: (state, action: PayloadAction<boolean>) => {
            state.addItemMode = action.payload;
        },
        setShowDialog: (state, action: PayloadAction<boolean>) => {
            state.showDialog = action.payload;
        },
        selectCategoryId: (state, action: PayloadAction<string>) => {
            state.selectedId = action.payload;
        },
        clearDuplicates: (state) => {
            state.duplicates = undefined;
        },
        clearAddedItemsCount: (state) => {
            state.addedItemsCount = 0;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(revertCatalogStores, () => initialState)
            .addCase(addCatalogItemService.pending, (state) => {
                state.error = undefined;
                state.duplicates = undefined;
                state.isLoading = true;
            })
            .addCase(addCatalogItemService.fulfilled, (state, action) => {
                state.isLoading = false;

                const duplicates = action.payload?.duplicates;
                const countOfAddedItems =
                    action.payload?.createdItems.length || 0;

                if (isArray(duplicates) && duplicates.length > 0) {
                    state.duplicates = duplicates;

                    if (countOfAddedItems) {
                        state.addedItemsCount = countOfAddedItems;
                    }
                } else {
                    state.showDialog = false;
                    toast('Items Successfully Added.');
                }
            })
            .addCase(addCatalogItemService.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { actions: addCatalogItemActions } = AddCatalogItemSlice;
export const { reducer: addCatalogItemReducer } = AddCatalogItemSlice;
