import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkConfig } from 'app/providers/StoreProvider';

import { notificationPageSize } from '../../conts/notificationsConst';
import { getNotificationsCurrentPage } from '../../selectors/notificationSelectors';
import { NotificationResponse } from '../../types/NotificationsSchema';

export const fetchMoreNotifications = createAsyncThunk<
    NotificationResponse,
    void,
    ThunkConfig<string>
>('notifications/fetchMoreNotifications', async (_, thunkApi) => {
    const { extra, rejectWithValue, getState } = thunkApi;

    const currentPage = getNotificationsCurrentPage(getState());

    const newPage = currentPage ? currentPage + 1 : 1;

    try {
        const response = await extra.api.get<NotificationResponse>(
            `/v1/users/notifications`,
            {
                params: {
                    pageSize: notificationPageSize,
                    page: newPage,
                },
            },
        );

        return response.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
