import { createSlice } from '@reduxjs/toolkit';

import { ProductSourcingSchema } from 'entities/ProductSourcing';

import { applySettingsToProperty } from '../services/applySettingsToProperty/applySettingsToProperty';
import { fetchSuppliers } from '../services/fetchSuppliers/fetchSuppliers';

const initialState: ProductSourcingSchema = {
    listOfSuppliers: null,
    isLoadingSuppliers: false,
    isSaveSettings: false,
    isSaveSettingsToAllProperties: false,
};

export const ProductSourcingSlice = createSlice({
    name: 'ProductSourcing',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSuppliers.pending, (state) => {
                state.isLoadingSuppliers = true;
            })
            .addCase(fetchSuppliers.fulfilled, (state, action) => {
                state.listOfSuppliers = action.payload.data;
                state.isLoadingSuppliers = false;
            })
            .addCase(fetchSuppliers.rejected, (state) => {
                state.isLoadingSuppliers = false;
            })
            .addCase(applySettingsToProperty.pending, (state) => {
                state.isSaveSettings = true;
            })
            .addCase(applySettingsToProperty.fulfilled, (state) => {
                state.isSaveSettings = false;
            })
            .addCase(applySettingsToProperty.rejected, (state) => {
                state.isSaveSettings = false;
            });
    },
});
export const { reducer: productSourcingReducer } = ProductSourcingSlice;
