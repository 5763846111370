import React from 'react';

import { dictionary } from 'shared/lib/unitOfMeasureUtility/unitOfMeasureUtility';

interface UnitOfMeasureDisplayProps {
    unitCode: string;
    quantity: number;
    language?: string;
}

const UnitOfMeasureDisplay: React.FC<UnitOfMeasureDisplayProps> = ({
    unitCode,
    quantity,
    language = 'en',
}) => {
    const unit = dictionary[unitCode];
    if (!unit || !unit.display) return null;

    // If the quantity is only 1, just show the term and skip the display with the quantity
    if (quantity === 1) {
        return <span>{unit.term}</span>;
    }

    // Add or remove the pluralization hidden behind the / depending on the quantity
    let phraseTemplate = unit.phrase[language] || unit.phrase.en;
    phraseTemplate = phraseTemplate.replace(/\/\w+/, (match) => {
        return quantity > 1 ? match.replace(/\//g, '') : '';
    });

    if (phraseTemplate.indexOf('|') > -1) {
        phraseTemplate = phraseTemplate
            .split(' ')
            .map((str) => {
                if (str.indexOf('|') > -1) {
                    return str.split('|')[quantity > 1 ? 1 : 0];
                }

                return str;
            })
            .join(' ');
    }

    // Format the number to display in the appropriate language format
    // If it's an int no decimals, but if it isn't an int let's limit it to 2 for now
    let formattedQuantity: string = '';
    if (Number.isInteger(quantity)) {
        formattedQuantity = new Intl.NumberFormat(language, {
            maximumFractionDigits: 0,
        }).format(quantity);
    } else {
        formattedQuantity = new Intl.NumberFormat(language, {
            maximumFractionDigits: 2,
        }).format(quantity);
    }

    const output = phraseTemplate.replace('{num}', formattedQuantity);
    return <span>{output}</span>;
};

export { UnitOfMeasureDisplay };
