import { Add } from '@mui/icons-material';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';

import { getLocalCategoriesList } from 'entities/Category';
import {
    getGlobalCategoriesList,
    getGlobalCategoriesListIsLoading,
    getLocalCategoriesListIsLoading,
} from 'entities/Category/model/selectors/categorySelectors';
import { getSelectedPropertyCatalogId } from 'entities/Property/model/selectors/PropertySelectors';
import { getUserCanEditPropertyCatalog } from 'entities/User/model/selectors/userPermissionsSeletors';
import { CategoryForm } from 'features/CategoryForm';
import { CategoryFormMode } from 'features/CategoryForm/module/types/categoryFormsTypes';
import { getRouteGlobalCatalog } from 'shared/const/router';
import { PropertyCatalogItemsMoveTo } from 'widgets/PropertyCatalog/models/consts/propertyCatalogItemsListTypes';

import { PropertyCatalogCategory } from './PropertyCatalogCategory';

interface PropertyCatalogCategoryListProps {
    isEditing: boolean;
    moveTo: PropertyCatalogItemsMoveTo;
}

const skeletonsNum = 9;

export const PropertyCatalogCategoryList = ({
    isEditing,
    moveTo,
}: PropertyCatalogCategoryListProps) => {
    const { t } = useTranslation();
    const localCategories = useSelector(getLocalCategoriesList);
    const globalCategories = useSelector(getGlobalCategoriesList);
    const isLoadingLocal = useSelector(getLocalCategoriesListIsLoading);
    const isLoadingGlobal = useSelector(getGlobalCategoriesListIsLoading);
    const canEditCatalog = useSelector(getUserCanEditPropertyCatalog);
    const catalogId = useSelector(getSelectedPropertyCatalogId);

    const [categoriesEditing, setCategoriesEditing] = useState(false);
    const isMoveToCatalog = moveTo === PropertyCatalogItemsMoveTo.CATALOG;

    const startEdit = () => {
        setCategoriesEditing(true);
    };

    const stopEdit = () => {
        setCategoriesEditing(false);
    };

    const isGlobalCatalog = useMatch(
        getRouteGlobalCatalog(':propertyId') + '/*',
    );

    const categoriesAreEditable =
        canEditCatalog && !isGlobalCatalog && isMoveToCatalog;

    const categories = isGlobalCatalog ? globalCategories : localCategories;
    const isLoading = isGlobalCatalog ? isLoadingGlobal : isLoadingLocal;

    const heightOfListForEditMode = isEditing
        ? 'calc(100vh - 332px)'
        : 'calc(100vh - 280px)';

    return (
        <Box
            bgcolor="grey.300"
            flexBasis="50%"
            style={{ flex: 1 }}
            display="flex"
            maxHeight={heightOfListForEditMode}
            flexDirection="column"
        >
            <Box display="flex" justifyContent="space-between" p="8px 16px">
                <Typography typography={'poppins.subtitle2'}>
                    {t('Categories')}
                </Typography>
                <Box display="flex" gap="24px">
                    {!isEditing && categoriesAreEditable ? (
                        <Button
                            data-testid="Add-category-button"
                            sx={{ p: 0 }}
                            onClick={startEdit}
                            disabled={categoriesEditing}
                            startIcon={<Add />}
                        >
                            {t('Add new category')}
                        </Button>
                    ) : null}
                </Box>
            </Box>
            <Box
                sx={{ overflowY: 'scroll', padding: '8px 16px' }}
                data-testid="categories-list"
            >
                {categoriesEditing && (
                    <CategoryForm
                        mode={CategoryFormMode.CREATE}
                        catalogId={catalogId}
                        onClose={stopEdit}
                        formTitle={t('New Category')}
                    />
                )}
                <>
                    {categories
                        ? Object.values(categories).map((category) => (
                              <Box
                                  data-testclass={`category`}
                                  mb="8px"
                                  display={isLoading ? 'none' : undefined}
                                  key={category.id}
                              >
                                  <PropertyCatalogCategory
                                      category={category}
                                      editable={categoriesAreEditable}
                                  />
                              </Box>
                          ))
                        : null}
                </>
                {Array.from({ length: skeletonsNum }).map((item, index) => (
                    <Skeleton
                        sx={{
                            mb: '8px',
                            display: !isLoading ? 'none' : undefined,
                        }}
                        key={index}
                        variant="rounded"
                        height="36px"
                    />
                ))}
            </Box>
        </Box>
    );
};
