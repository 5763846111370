import { Box, Button, Dialog, Typography } from '@mui/material';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { DialogBoxContent } from '../DialogBoxContent/DialogBoxContent';
import { Loader } from '../Loader/Loader';

interface RemoveOrderModalProps {
    isShowDeleteOrder: boolean;
    isLoadingDeleteOrder: boolean;
    title: string;
    text: string;
    deleteButtonText?: string;
    onCloseDeleteOrder: () => void;
    onSubmitDeleteOrder: () => void;
}

export default memo(
    ({
        isShowDeleteOrder,
        title,
        text,
        onCloseDeleteOrder,
        onSubmitDeleteOrder,
        isLoadingDeleteOrder,
        deleteButtonText,
    }: RemoveOrderModalProps) => {
        const { t } = useTranslation();

        const cancelOrderModal = useCallback(() => {
            if (!isLoadingDeleteOrder) {
                onCloseDeleteOrder();
            }
        }, [isLoadingDeleteOrder, onCloseDeleteOrder]);

        const submitOrderModal = useCallback(() => {
            if (!isLoadingDeleteOrder) {
                onSubmitDeleteOrder();
            }
        }, [isLoadingDeleteOrder, onSubmitDeleteOrder]);

        return (
            isShowDeleteOrder && (
                <Dialog data-testid="modal-order-delete" open>
                    <DialogBoxContent
                        onClose={cancelOrderModal}
                        buttons={
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                width="100%"
                                gap="10px"
                            >
                                <Button onClick={cancelOrderModal}>
                                    {t('Cancel')}
                                </Button>
                                <Button
                                    data-testclass="btn-order-delete"
                                    variant="contained"
                                    startIcon={
                                        isLoadingDeleteOrder && (
                                            <Loader color="inherit" />
                                        )
                                    }
                                    disabled={isLoadingDeleteOrder}
                                    onClick={submitOrderModal}
                                    color="error"
                                >
                                    {deleteButtonText}
                                </Button>
                            </Box>
                        }
                    >
                        <Box maxWidth="400px">
                            <Typography
                                mb="20px"
                                typography="poppins.subtitle2"
                            >
                                {title}
                            </Typography>
                            <Typography
                                mb="8px"
                                typography="openSans.body2"
                                dangerouslySetInnerHTML={{ __html: text }}
                            />
                        </Box>
                    </DialogBoxContent>
                </Dialog>
            )
        );
    },
);
