import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { NotificationsSchema } from '../types/NotificationsSchema';

const initialState: NotificationsSchema = {
    isActive: false,
};

export const NotificationsSlice = createSlice({
    name: 'Notifications',
    initialState,
    reducers: {
        setActive: (state, action: PayloadAction<boolean>) => {
            state.isActive = action.payload;
        },
    },
});

export const { actions: NotificationsActions } = NotificationsSlice;
export const { reducer: NotificationsReducer } = NotificationsSlice;
