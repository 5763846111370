import { Notifications as NotificationIcon } from '@mui/icons-material';
import { Box, Skeleton, Theme, alpha } from '@mui/material';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
    getHasUnreadNotifications,
    getNotificationsAreLoading,
} from 'entities/Notification/model/selectors/notificationSelectors';
import { getNotificationsIsActive } from 'features/Notifications/model/selectors/notificationsSelectors';

interface NotificationsButtonProps {
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export const NotificationsButton = memo(
    ({ onClick }: NotificationsButtonProps) => {
        const isActive = useSelector(getNotificationsIsActive);
        const isLoading = useSelector(getNotificationsAreLoading);
        const hasUnread = useSelector(getHasUnreadNotifications);

        const iconStyles = useCallback(
            (theme: Theme) => ({
                color: isActive
                    ? 'primary.400'
                    : alpha(theme.palette.common.black, 0.5),
            }),
            [isActive],
        );

        return !isLoading ? (
            <Box
                display="flex"
                position="relative"
                alignItems="center"
                color="black"
                sx={{ cursor: 'pointer' }}
                onClick={onClick}
                data-testid="NotificationsButton"
            >
                <NotificationIcon sx={iconStyles} />
                {hasUnread && (
                    <Box
                        position="absolute"
                        right="0"
                        top="0"
                        bgcolor="background.paper"
                        width="12px"
                        height="12px"
                        borderRadius="50%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box
                            width="8px"
                            height="8px"
                            borderRadius="50%"
                            bgcolor="primary.400"
                        />
                    </Box>
                )}
            </Box>
        ) : (
            <Skeleton
                data-testid="NotificationsButton.Skeleton"
                variant="rounded"
                sx={{ width: '32px', height: '32px', borderRadius: '50%' }}
            />
        );
    },
);
