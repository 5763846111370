import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkConfig } from 'app/providers/StoreProvider';

export const getLabels = createAsyncThunk<
    Blob,
    { catalogId: string; isInventory: boolean },
    ThunkConfig<any>
>('catalog/getLabels', async ({ catalogId, isInventory }, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;
    const extraPathForRequestInventory = isInventory ? '/inventory' : '';
    try {
        const response = await extra.api.get<Blob>(
            `/v1/catalogs/${catalogId}${extraPathForRequestInventory}/labels`,
            {
                responseType: 'blob',
            },
        );

        return response?.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
