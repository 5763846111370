import { Box } from '@mui/material';
import { ReactNode } from 'react';

import { PageNavbar } from 'features/PageNavbar';
import { PageNavItem } from 'features/PageNavbar/model/types/PageNavBarTypes';
import { innerNavFixPosition, innerNavHeight } from 'shared/const/styles';

interface PageProps {
    children: ReactNode;
    navList?: PageNavItem[];
    navTitle?: string;
    bgColor?: string;
    padding?: string;
    dataTestId?: string;
}

export const Page = ({
    children,
    navList,
    navTitle,
    bgColor,
    padding,
    dataTestId,
}: PageProps) => {
    return (
        <Box
            padding="24px"
            display="flex"
            gap="8px"
            data-testid={dataTestId ?? 'Page'}
        >
            <Box
                position="sticky"
                top={innerNavFixPosition}
                bgcolor="background.paper"
                borderRadius="12px"
                overflow="hidden"
                height={innerNavHeight}
                p="12px 0"
            >
                {navList && <PageNavbar navList={navList} title={navTitle} />}
            </Box>
            <Box
                flex="1"
                bgcolor={bgColor || 'background.paper'}
                borderRadius="12px"
                overflow="hidden"
                padding={padding || '24px 16px'}
                height={innerNavHeight}
                sx={{ overflowY: 'scroll', position: 'relative' }}
                id="right_side_page"
                display="flex"
                flexDirection="column"
            >
                {children}
            </Box>
        </Box>
    );
};
