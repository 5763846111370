import { Box, Typography } from '@mui/material';
import { memo } from 'react';

interface CatalogItemRowProps {
    label: string;
    value: string | number;
}

export const CatalogItemRow = memo(({ label, value }: CatalogItemRowProps) => {
    return (
        <Box
            date-testclass={'attribute'}
            display="flex"
            justifyContent="space-between"
        >
            <Typography data-testclass={'label'} typography="openSans.body2">
                {label}
            </Typography>
            <Typography
                data-testclass={'value'}
                typography="openSans.subtitle2"
                textAlign="right"
            >
                {value}
            </Typography>
        </Box>
    );
});
