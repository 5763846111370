import { createSlice } from '@reduxjs/toolkit';

import { getLabels } from '../services/getLabels';
import { getSelectedLabels } from '../services/getSelectedLabels';
import { GetLabelsSchema } from '../types/LabelsSchema';

const initialState: GetLabelsSchema = {
    isLoadingLabels: false,
    labelsFile: null,
    selectedLabelsFile: null,
    isLoadingSelectedLabels: false,
};

export const getLabelsSlice = createSlice({
    name: 'GetLabels',
    initialState,
    reducers: {
        clearStateOfLabels: (state) => {
            state.labelsFile = null;
            state.selectedLabelsFile = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLabels.pending, (state) => {
                state.isLoadingLabels = true;
            })
            .addCase(getLabels.fulfilled, (state, action) => {
                const file = action?.payload;
                state.labelsFile = file;
                state.isLoadingLabels = false;
            })
            .addCase(getLabels.rejected, (state, action) => {
                state.isLoadingLabels = false;
                state.error = action.payload;
            })
            .addCase(getSelectedLabels.pending, (state) => {
                state.isLoadingSelectedLabels = true;
            })
            .addCase(getSelectedLabels.fulfilled, (state, action) => {
                const file = action?.payload;
                state.selectedLabelsFile = file;
                state.isLoadingSelectedLabels = false;
            })
            .addCase(getSelectedLabels.rejected, (state, action) => {
                state.isLoadingSelectedLabels = false;
                state.error = action.payload;
            });
    },
});

export const { actions: getLabelsActions } = getLabelsSlice;
export const { reducer: getLabelsReducer } = getLabelsSlice;
