import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { getLocalCategoriesList } from 'entities/Category/model/selectors/categorySelectors';

interface CategoryLabelProps {
    categoryId: string;
}

export const CategoryLabel = ({ categoryId, ...props }: CategoryLabelProps) => {
    const categories = useSelector(getLocalCategoriesList);

    const color = categories?.[categoryId]?.color;
    const title = categories?.[categoryId]?.title;
    const glCode = categories?.[categoryId]?.glCode;

    return (
        categories && (
            <Box display="flex" gap="4px" alignItems="center" {...props}>
                <Box
                    height="8px"
                    width="8px"
                    bgcolor={color}
                    borderRadius="50%"
                />
                <Typography
                    sx={{
                        overflowWrap: 'break-word',
                        wordWrap: 'break-word',
                    }}
                    typography="openSans.caption"
                    height={'16px'}
                >
                    {title}
                </Typography>
                <Typography typography="openSans.caption">
                    {glCode ? ` | ${glCode}` : ''}
                </Typography>
            </Box>
        )
    );
};
