import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkConfig } from 'app/providers/StoreProvider';
import { Category } from 'entities/Category';

interface CreateCategoryServiceParams {
    catalogId: string;
    glCode: string;
    color: string;
    title: string;
}

export const createCategoryService = createAsyncThunk<
    Category,
    CreateCategoryServiceParams,
    ThunkConfig<string>
>(
    'category/CreateCategoryService',
    async ({ catalogId, title, color, glCode }, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.post<{ category: Category }>(
                `/v1/catalogs/${catalogId}/categories`,
                {
                    color,
                    glCode,
                    title,
                },
            );
            return response.data.category;
        } catch (e) {
            const errorData = e?.response?.data;

            if (errorData) {
                return rejectWithValue(errorData);
            }

            return rejectWithValue('error');
        }
    },
);
