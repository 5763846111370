import placeholder from 'shared/assets/images/catalog-item.svg';
import { Image } from 'widgets/Image';

interface CatalogItemImageProps {
    src?: string | null;
    isFull?: boolean | false;
    height?: string | null;
    width?: string | null;
}

export const CatalogItemImage = ({
    src,
    isFull = false,
    height = '100%',
    width = '100%',
}: CatalogItemImageProps) => {
    if (isFull) {
        return (
            <Image
                src={src}
                imageStyle={{
                    width: 'auto',
                    height: 'auto',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                    margin: '0 auto',
                }}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100%',
                    width: '100%',
                    margin: '0 auto',
                    overflow: 'hidden',
                }}
                placeholder={placeholder}
            />
        );
    }

    return (
        <Image
            src={src}
            imageStyle={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
            }}
            style={{
                height: height,
                width: width,
                margin: '0 auto',
                overflow: 'hidden',
                border: '1px solid #DFDFDF',
                borderRadius: '4px',
                boxSizing: 'border-box',
                padding: '10px',
            }}
            placeholder={placeholder}
        />
    );
};
