import { Box, Skeleton } from '@mui/material';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import {
    getPropertyItem,
    propertyItemLoading,
} from 'features/fetchPropertyById/model/selectors/fetchPropertyByIdSelectors';
import { maxWidthContent } from 'shared/const/styles';
import { PropertyImage } from 'shared/ui/PropertyImage';

import { PropertyCardContactInfo } from './PropertyCardContactInfo';
import { PropertyCardInfo } from './PropertyCardInfo';
import { PropertyCardMainInfo } from './PropertyCardMainInfo';

export const PropertyCard = memo(() => {
    const propertyItem = useSelector(getPropertyItem);
    const loading = useSelector(propertyItemLoading);

    return (
        <Box maxWidth={maxWidthContent} display="flex">
            <>
                <Box
                    bgcolor="grey.300"
                    p="16px"
                    borderRadius="12px 0 0 12px"
                    overflow="hidden"
                    flexBasis="50%"
                >
                    <Box borderRadius="12px" overflow="hidden" mb="16px">
                        {loading ? (
                            <Skeleton
                                variant="rounded"
                                style={{
                                    aspectRatio: '380/220',
                                }}
                                height="auto"
                                width="100%"
                            />
                        ) : (
                            <PropertyImage src={propertyItem.imageUrl} />
                        )}
                    </Box>
                    {loading ? (
                        <Skeleton
                            variant="rounded"
                            height="400px"
                            width="100%"
                        />
                    ) : (
                        <PropertyCardMainInfo propertyItem={propertyItem} />
                    )}
                </Box>
                <Box
                    p="16px"
                    flexBasis="50%"
                    borderRadius="0 12px 12px 0"
                    border="1px solid"
                    borderColor="grey.500"
                >
                    {loading ? (
                        <Skeleton
                            variant="rounded"
                            height="140px"
                            width="100%"
                            sx={{ mb: '32px' }}
                        />
                    ) : (
                        <PropertyCardInfo
                            propertyItem={propertyItem}
                            mb="32px"
                        />
                    )}

                    {loading ? (
                        <Skeleton
                            variant="rounded"
                            height="460px"
                            width="100%"
                        />
                    ) : (
                        <PropertyCardContactInfo
                            propertyItem={propertyItem?.allContacts}
                        />
                    )}
                </Box>
            </>
        </Box>
    );
});
