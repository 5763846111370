import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getUserData } from 'entities/User';
import { useFlipt } from 'shared/config/featureFlag/FliptContext';

export const useFeatureFlag = (flagKey: string) => {
    const user = useSelector(getUserData);
    const fliptClient = useFlipt();
    const [flagValue, setFlagValue] = useState<boolean | null>(null);

    useEffect(() => {
        const fetchFlag = () => {
            if (fliptClient) {
                try {
                    const response = fliptClient.evaluateBoolean(
                        flagKey,
                        user?.id,
                        {
                            environment: process.env.REACT_APP_SENTRY_ENV,
                        },
                    );
                    if (response.status === 'failure') {
                        setFlagValue(false);
                    }
                    if (response.status === 'success') {
                        setFlagValue(
                            flagKey === 'renovation_enabled'
                                ? false
                                : response?.result?.enabled,
                        );
                    }
                } catch (error) {
                    setFlagValue(false);
                }
            }
        };

        fetchFlag();
    }, [fliptClient, flagKey, user]);

    return flagValue;
};
