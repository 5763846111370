import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { mapCategories } from 'entities/Category/lib/mapCategories';
import { mapGlobalCategories } from 'entities/Category/lib/mapGlobalCategories';
import { updateAndGetCategoryList } from 'entities/Category/lib/updateAndGetCategoryList';
import { revertCatalogStores } from 'shared/ui/store/resetStoresAction';

import { createCategoryService } from '../services/createCategory/createCategoryService';
import { changeCategoryService } from '../services/editCategory/changeCategoryService';
import { fetchCategories } from '../services/fetchCategories/fetchCategories';
import { fetchGlobalCategories } from '../services/fetchGlobalCategories/fetchGlobalCategories';
import {
    Category,
    CategorySchema,
    GlobalCategory,
} from '../types/CategorySchema';

const initialState: CategorySchema = {
    localListIsLoading: false,
    globalListIsLoading: false,
    localListInitial: [],
};

export const CategorySlice = createSlice({
    name: 'Category',
    initialState,
    reducers: {
        selectCategory: (state, action: PayloadAction<Category>) => {
            state.selectedData = action.payload;
        },
        selectSubCategory: (state, action: PayloadAction<GlobalCategory>) => {
            state.selectedSubCategory = action.payload;
        },
        clearCategorySelection: (state) => {
            state.selectedData = null;
            state.selectedSubCategory = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(revertCatalogStores, () => initialState)
            .addCase(fetchCategories.pending, (state) => {
                state.error = null;
                state.localListIsLoading = true;
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.localCategories = mapCategories(action.payload);
                state.localListInitial = action.payload;
                state.localListIsLoading = false;
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                state.localListIsLoading = false;
                state.error = action.payload;
            })
            .addCase(fetchGlobalCategories.pending, (state) => {
                state.error = null;
                state.globalListIsLoading = true;
            })
            .addCase(fetchGlobalCategories.fulfilled, (state, action) => {
                const filteredData = mapGlobalCategories(action.payload);

                state.globalCategories = filteredData;
                state.globalListIsLoading = false;
            })
            .addCase(fetchGlobalCategories.rejected, (state, action) => {
                state.globalListIsLoading = false;
                state.error = action.payload;
            })
            .addCase(changeCategoryService.pending, (state) => {
                state.error = null;
                state.localListIsLoading = true;
            })
            .addCase(changeCategoryService.fulfilled, (state, action) => {
                const changeCategoryItem = action.payload;
                const updatedLocalCategories = updateAndGetCategoryList(
                    state.localListInitial,
                    changeCategoryItem,
                );
                state.localListInitial = updatedLocalCategories;
                state.localCategories = mapCategories(updatedLocalCategories);
                state.localListIsLoading = false;
            })
            .addCase(changeCategoryService.rejected, (state, action) => {
                state.localListIsLoading = false;
                state.error = action.payload;
            })
            .addCase(createCategoryService.pending, (state) => {
                state.error = null;
                state.localListIsLoading = true;
            })
            .addCase(createCategoryService.fulfilled, (state, action) => {
                const createdCategoryItem = action.payload;
                const updatedLocalCategories = updateAndGetCategoryList(
                    state.localListInitial,
                    createdCategoryItem,
                );

                state.localListInitial = updatedLocalCategories;
                state.localCategories = mapCategories(updatedLocalCategories);
                state.localListIsLoading = false;
            })
            .addCase(createCategoryService.rejected, (state, action) => {
                state.localListIsLoading = false;
                state.error = action.payload;
            });
    },
});

export const { actions: categoryActions } = CategorySlice;
export const { reducer: categoryReducer } = CategorySlice;
