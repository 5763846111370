import {
    Accordion,
    AccordionDetails,
    Box,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
    alpha,
} from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { InteractionStatusSchema } from 'entities/Order/model/types/OrderSchema';
import { AccordionHeader } from 'shared/ui/AccordionHeader/AccordionHeader';

const statusCategoryMapping = {
    Processing: [
        'WAITING_ACCEPTANCE',
        'PROCESSING',
        'ACKNOWLEDGED',
        'ATTENTION_REQUIRED',
    ],
    Accepted: ['ACCEPTED'],
    ['Partially accepted']: ['PARTIALLY_ACCEPTED'],
    Shipped: ['SHIPPED'],
    Invoiced: ['INVOICED'],
    Rejected: ['FAILED', 'ACKNOWLEDGEMENT_MISSING', 'REFUSED'],
};

export const OrderStatusDetails = ({
    interactionStatus,
}: {
    interactionStatus: InteractionStatusSchema[];
}) => {
    const { t } = useTranslation();

    const getStatusStyles = (status: string) => {
        switch (status) {
            case 'ACCEPTED':
            case 'INVOICED':
                return {
                    background: '#CBF4C9',
                    color: '#377963',
                };
            case 'FAILED':
            case 'ACKNOWLEDGEMENT_MISSING':
            case 'REFUSED':
                return {
                    background: '#DCDBDB',
                    color: '#6E6E6E',
                };
            default:
                return {
                    background: '#D9ECFF',
                    color: '#3F6EB7',
                };
        }
    };

    const getStatusCategory = useCallback((status: string) => {
        for (const [category, statuses] of Object.entries(
            statusCategoryMapping,
        )) {
            if (statuses.includes(status)) {
                return category;
            }
        }
    }, []);

    return (
        <Box sx={{ my: '16px' }}>
            <Accordion
                sx={(theme) => ({
                    bgcolor: alpha(theme.palette.grey[500], 0.5),
                    borderRadius: '12px',
                })}
                defaultExpanded
            >
                <AccordionHeader title={t('Fulfillment status')} />
                <AccordionDetails sx={{ p: '12px' }}>
                    {interactionStatus.map((statusItem, index) => (
                        <Box key={index} sx={{ mb: '12px' }}>
                            <Box
                                sx={{
                                    mb: '4px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '15px',
                                        fontWeight: '600',
                                    }}
                                >
                                    {statusItem.supplierTitle || 'Ferguson'}
                                </Typography>
                                <Typography
                                    sx={{
                                        p: '6px 12px',
                                        fontFamily: 'Open Sans',
                                        fontSize: '13px',
                                        fontWeight: '700',
                                        borderRadius: '22px',
                                        ...getStatusStyles(statusItem.status),
                                    }}
                                >
                                    {getStatusCategory(statusItem.status)}
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={'fulfilled'}
                                        onChange={() => null}
                                    >
                                        <FormControlLabel
                                            value="fulfilled"
                                            control={<Radio />}
                                            label="Fulfilled"
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <Typography
                                    sx={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '15px',
                                        fontWeight: 400,
                                    }}
                                >
                                    {statusItem.allocated}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};
