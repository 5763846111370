import { createBrowserHistory } from 'history';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom/client';

import App from 'app/App';
import { ErrorBoundary } from 'app/providers/ErrorBoundary';
import { StoreProvider } from 'app/providers/StoreProvider';
import MUThemeProvider from 'app/providers/ThemeProvider/ui/ThemeProvider';
import { CustomRouter } from 'app/providers/router/ui/CustomRoute';
import { NavigationBlockProvider } from 'features/NavigationBlockModal/ui/NavigationBlockProvider/NavigationBlockProvider';

import 'shared/config/sentry/sentry';
import 'shared/config/i18n/i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <StoreProvider>
        <NavigationBlockProvider>
            <CustomRouter history={createBrowserHistory()}>
                <MUThemeProvider>
                    <ErrorBoundary>
                        <DndProvider backend={HTML5Backend}>
                            <App />
                        </DndProvider>
                    </ErrorBoundary>
                </MUThemeProvider>
            </CustomRouter>
        </NavigationBlockProvider>
    </StoreProvider>,
);
