import { Box, BoxProps, Typography } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Property } from 'entities/Property/model/types/PropertySchema';

import { PropertyCardShortInfoBlock } from './PropertyCardShortInfoBlock';

type PropertyCardContactInfoProps = {
    propertyItem: Property['allContacts'];
} & BoxProps;

export const PropertyCardContactInfo = memo(
    ({ mb, propertyItem }: PropertyCardContactInfoProps) => {
        const { t } = useTranslation();

        return (
            <Box mb={mb}>
                <Typography typography={'poppins.subtitle1'} mb="16px">
                    {t('Contact Info')}
                </Typography>
                <PropertyCardShortInfoBlock
                    mb="8px"
                    title={t('Property Manager')}
                    data={propertyItem?.propertyManagement}
                />
                <PropertyCardShortInfoBlock
                    mb="8px"
                    title={t('Regional Manager')}
                    data={propertyItem?.regionalManager}
                />
                <PropertyCardShortInfoBlock
                    title={t('Maintenance Supervisor')}
                    data={propertyItem?.maintenanceSupervisor}
                />
            </Box>
        );
    },
);
