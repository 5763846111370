import { Box, Button, Typography } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CatalogItemsSelectedCounter } from 'entities/CatalogItem';
import { getSelectedCatalogItems } from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { getLocalCategoriesList } from 'entities/Category/model/selectors/categorySelectors';
import { getSelectedPropertyCatalogId } from 'entities/Property/model/selectors/PropertySelectors';
import { getAddCatalogItemIsLoading } from 'features/AddCatalogItem/model/selectors/addCatalogItemSelectors';
import { addCatalogItemService } from 'features/AddCatalogItem/model/services/addCatalogItemService';
import { AddPropertyCatalogCategoryAtList } from 'features/addPropertyCatalogCategory';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { DialogBoxContent } from 'shared/ui/DialogBoxContent/DialogBoxContent';
import { Loader } from 'shared/ui/Loader/Loader';

import { AddCatalogItemCategory } from '../AddCatalogItem/AddCatalogItemCategory';

interface FormProps {
    category: string;
}

interface AddCatalogItemDialogFormProps {
    onClose: () => void;
}

export const AddCatalogItemDialogForm = ({
    onClose,
}: AddCatalogItemDialogFormProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const categories = useSelector(getLocalCategoriesList);
    const selectedItems = useSelector(getSelectedCatalogItems);
    const isLoading = useSelector(getAddCatalogItemIsLoading);
    const catalogId = useSelector(getSelectedPropertyCatalogId);

    const handleSubmit = async (values: FormProps): Promise<void> => {
        const data = {
            items: Object.keys(selectedItems).map((itemUuid) => ({
                itemUuid,
                categoryId: values.category,
            })),
        };
        await dispatch(
            addCatalogItemService({
                data,
                catalogId,
            }),
        );
    };

    return (
        <Formik onSubmit={handleSubmit} initialValues={{ category: '' }}>
            {(props: FormikProps<FormProps>) => {
                return (
                    <Form>
                        <DialogBoxContent
                            onClose={onClose}
                            buttons={
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    width="100%"
                                >
                                    <CatalogItemsSelectedCounter />
                                    <Box>
                                        <Button onClick={onClose}>
                                            {t('Cancel')}
                                        </Button>
                                        <Button
                                            disabled={
                                                !props.values.category ||
                                                isLoading
                                            }
                                            startIcon={
                                                isLoading && (
                                                    <Loader color="inherit" />
                                                )
                                            }
                                            variant="contained"
                                            type="submit"
                                        >
                                            {t('Add')}
                                        </Button>
                                    </Box>
                                </Box>
                            }
                        >
                            <Box
                                data-testid="Add-new-category-title"
                                p="0 24px"
                            >
                                <Typography
                                    typography={'poppins.subtitle2'}
                                    mb="18px"
                                >
                                    {t('Select Category')}
                                </Typography>
                                <AddPropertyCatalogCategoryAtList />

                                <Box
                                    data-testid="Categories-list"
                                    display="flex"
                                    flexDirection="column"
                                    gap="8px 0"
                                    mt="14px"
                                >
                                    {categories
                                        ? Object.values(categories).map(
                                              (category) => (
                                                  <AddCatalogItemCategory
                                                      key={category.id}
                                                      id={category.id}
                                                      color={category.color}
                                                      catalogId={catalogId}
                                                      title={category.title}
                                                      glCode={category.glCode}
                                                  />
                                              ),
                                          )
                                        : null}
                                </Box>
                            </Box>
                        </DialogBoxContent>
                    </Form>
                );
            }}
        </Formik>
    );
};
