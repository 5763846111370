import { Box, Typography, alpha } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useMatch } from 'react-router-dom';

import { CatalogItemsSelectedCounter } from 'entities/CatalogItem';
import {
    getCatalogItems,
    getCatalogItemsAreLoading,
    getCatalogItemsTotalCount,
    getSelectedCatalogItem,
    getSelectedCatalogItemsTotal,
} from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { AddToPropertyCatalogButton } from 'features/AddCatalogItem';
import { CreateOrderWithItemsButton } from 'features/AddItemsToOrder';
import {
    SearchCatalogItemsField,
    SearchGlobalCatalogItems,
} from 'features/SearchCatalogItems';
import { getSearchCatalogItemIsActive } from 'features/SearchCatalogItems/model/selectors/searchCatalogItemsSelectors';
import { searchCatalogItemsActions } from 'features/SearchCatalogItems/model/slices/SearchCatalogItemsSlice';
import { getRouteGlobalCatalog } from 'shared/const/router';
import { itemsContainerHeightOffset } from 'shared/const/styles';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import {
    PropertyCatalogItemDataDefiner,
    PropertyCatalogItemsList,
    PropertyCatalogItemsMoveTo,
} from 'widgets/PropertyCatalog';

interface SearchCatalogItemsProps {
    catalogId: string;
    expandable?: boolean;
    moveTo: PropertyCatalogItemsMoveTo;
}

export const SearchCatalogItems = ({
    catalogId,
    expandable,
    moveTo,
}: SearchCatalogItemsProps) => {
    const { t } = useTranslation();
    const location = useLocation();
    const isActive = useSelector(getSearchCatalogItemIsActive);
    const isLoading = useSelector(getCatalogItemsAreLoading);
    const catalogItems = useSelector(getCatalogItems);
    const resultsCount = useSelector(getCatalogItemsTotalCount);
    const totalItemsCount = useSelector(getSelectedCatalogItemsTotal);
    const selectedItem = useSelector(getSelectedCatalogItem);
    const dispatch = useAppDispatch();

    const isGlobalCatalog = useMatch(
        getRouteGlobalCatalog(':propertyId') + '/*',
    );
    const isOrderEditing = location.state?.selectedOrderId;
    const isMoveToCatalog = moveTo === PropertyCatalogItemsMoveTo.CATALOG;
    const isMoveToOrder = moveTo === PropertyCatalogItemsMoveTo.ORDER;
    const isViewOnly = moveTo === PropertyCatalogItemsMoveTo.VIEW_ONLY;
    const isExpanded = expandable && isActive;

    const resultWord = resultsCount === 1 ? t('Result') : t('Results');

    const actionBarHeight = isMoveToCatalog ? 90 : 144;

    const itemsContainerHeightWithActions = `calc(100vh - ${
        itemsContainerHeightOffset + actionBarHeight
    }px)`;
    const itemsContainerHeightWithoutActions = `calc(100vh - ${
        itemsContainerHeightOffset + 26
    }px)`;

    useEffect(
        () => () => {
            dispatch(searchCatalogItemsActions.setActive(false));
            dispatch(searchCatalogItemsActions.setGlobalCatItemSearchValue(''));
        },
        [dispatch],
    );

    return (
        <Box>
            <Box
                flexBasis="50%"
                display={selectedItem && isExpanded ? 'none' : 'flex'}
                flexDirection="column"
                borderRadius="8px"
                border="1px solid"
                borderColor="divider"
            >
                {isGlobalCatalog ? (
                    <SearchGlobalCatalogItems hideBorder />
                ) : (
                    <SearchCatalogItemsField catalogId={catalogId} hideBorder />
                )}
                {isExpanded && (
                    <Box
                        padding="0 16px 16px"
                        maxHeight={
                            !isViewOnly
                                ? itemsContainerHeightWithActions
                                : itemsContainerHeightWithoutActions
                        }
                        sx={{ overflowX: 'hidden', overflowY: 'scroll' }}
                    >
                        {!isLoading && (
                            <Box
                                position="sticky"
                                top="0"
                                p="11px 0"
                                borderBottom="1px solid"
                                borderColor="divider"
                                bgcolor="background.paper"
                                zIndex="drawer"
                            >
                                <Typography
                                    typography="openSans.body2Medium"
                                    color={(theme) =>
                                        alpha(theme.palette.common.black, 0.5)
                                    }
                                >
                                    {resultsCount} {resultWord} {t('Found')}
                                </Typography>
                            </Box>
                        )}
                        <PropertyCatalogItemsList
                            items={catalogItems}
                            moveTo={moveTo}
                            loading={isLoading}
                            quickEdit={isOrderEditing}
                            isSearch
                        />
                    </Box>
                )}
                {isExpanded && !isViewOnly && (
                    <Box display="flex" justifyContent="flex-end" mr="16px">
                        <Box>
                            <CatalogItemsSelectedCounter />
                            <Box
                                mb="16px"
                                width="250px"
                                display="flex"
                                justifyContent="flex-end"
                            >
                                {isMoveToCatalog && (
                                    <AddToPropertyCatalogButton
                                        disabled={totalItemsCount === 0}
                                    />
                                )}
                                {isMoveToOrder && (
                                    <CreateOrderWithItemsButton />
                                )}
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
            {isExpanded && selectedItem && (
                <Box
                    p="16px"
                    borderRadius="8px"
                    border="1px solid"
                    borderColor="divider"
                >
                    <PropertyCatalogItemDataDefiner
                        isDetailed
                        item={selectedItem}
                        moveTo={moveTo}
                        quickEdit={isOrderEditing}
                        isSearch
                    />
                </Box>
            )}
        </Box>
    );
};
