import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkConfig } from 'app/providers/StoreProvider';

import {
    FetchOrderParams,
    FetchOrderResponse,
    Order,
} from '../../types/OrderSchema';

export const fetchOrder = createAsyncThunk<
    FetchOrderResponse,
    FetchOrderParams,
    ThunkConfig<string>
>('order/fetchOrder', async ({ id, isCatalog, onNotFound }, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.get<Order>(`/v1/orders/${id}`);

        return { data: response.data, isCatalog: Boolean(isCatalog) };
    } catch (e) {
        if (e?.response?.status === 404 && onNotFound) {
            onNotFound();
        }

        return rejectWithValue('error');
    }
});
