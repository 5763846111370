import * as Sentry from '@sentry/react';
import { memo, useEffect } from 'react';

import { AuthService } from 'shared/services/authService';

export const LoginRedirectPage = memo(() => {
    const onLogin = async () => {
        const authService = new AuthService();
        await authService.login();
    };

    useEffect(() => {
        onLogin().catch((error) => {
            Sentry.captureException(error);
        });
    }, []);

    return <></>;
});
