import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkConfig } from 'app/providers/StoreProvider';
import { CatalogItem } from 'entities/CatalogItem/model/types/CatalogItemSchema';

export const fetchInventoryItems = createAsyncThunk<
    CatalogItem[],
    string,
    ThunkConfig<string>
>('catalogItem/fetchInventoryItems', async (catalogId, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.get<CatalogItem[]>(
            `/v1/catalogs/${catalogId}`,
            {
                params: {
                    isInventory: true,
                    is_loading_proposals: false,
                },
            },
        );

        return response.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
