import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Typography } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { fetchGlobalCatalogItems } from 'entities/CatalogItem';
import { getCatalogItemsAreLoading } from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { catalogItemActions } from 'entities/CatalogItem/model/slices/CatalogItemSlice';
import {
    getSelectCategoryId,
    getSelectCategoryTitle,
    getSelectSubCategoryId,
    getSelectSubCategoryName,
} from 'entities/Category/model/selectors/categorySelectors';
import { categoryActions } from 'entities/Category/model/slices/CategorySlice';
import {
    getSearchCatalogItemIsActive,
    getSearchGlobalCatalogItemValue,
} from 'features/SearchCatalogItems/model/selectors/searchCatalogItemsSelectors';
import { searchCatalogItemsActions } from 'features/SearchCatalogItems/model/slices/SearchCatalogItemsSlice';
import { getRouteGlobalCatalog } from 'shared/const/router';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { useFetchWIthController } from 'shared/lib/hooks/useFetchWIthController';
import { useUnmountEffect } from 'shared/lib/hooks/useUnmountEffect';
import { SearchField } from 'shared/ui/SearchField/SearchField';

interface SearchGlobalCatalogItemsProps {
    hideBorder: boolean;
}

export const SearchGlobalCatalogItems = memo(
    ({ hideBorder }: SearchGlobalCatalogItemsProps) => {
        const { t } = useTranslation();
        const dispatch = useAppDispatch();
        const fetchWithController = useFetchWIthController();
        const navigate = useNavigate();
        const params = useParams();

        const searchValue = useSelector(getSearchGlobalCatalogItemValue);

        const isActive = useSelector(getSearchCatalogItemIsActive);
        const isLoading = useSelector(getCatalogItemsAreLoading);

        const selectedCategoryTitle = useSelector(getSelectCategoryTitle);
        const selectedSubCategoryTitle = useSelector(getSelectSubCategoryName);
        const selectedCategoryId = useSelector(getSelectCategoryId);
        const selectedSubCategoryId = useSelector(getSelectSubCategoryId);

        const searchPlaceholder = `${t('Search by Item Name')}, ${t(
            'Model or SKU',
        )}...`;

        const getCategorySelectChipLabel = () => {
            if (!selectedCategoryTitle && !selectedSubCategoryTitle)
                return null;

            return (
                <Box display="flex">
                    <Typography>{selectedCategoryTitle}</Typography>
                    {selectedSubCategoryTitle && (
                        <>
                            <KeyboardArrowRightIcon />
                            <Typography>{selectedSubCategoryTitle}</Typography>
                        </>
                    )}
                </Box>
            );
        };

        useUnmountEffect(() => {
            dispatch(searchCatalogItemsActions.setGlobalCatItemSearchValue(''));
        });

        const showExpandedSearch = (searchStr: string): void => {
            if (selectedCategoryTitle || selectedSubCategoryTitle) return null;

            if (!searchStr && isActive) {
                dispatch(searchCatalogItemsActions.setActive(false));
            } else if (searchStr && !isActive) {
                dispatch(searchCatalogItemsActions.setActive(true));
            }

            if (!isLoading && searchStr) {
                dispatch(catalogItemActions.setLoading(true));
            }
        };

        const onSetValue = async (searchStr: string) => {
            const categoryIdSearch =
                selectedSubCategoryId || selectedCategoryId;

            showExpandedSearch(searchStr);

            dispatch(
                searchCatalogItemsActions.setGlobalCatItemSearchValue(
                    searchStr,
                ),
            );

            fetchWithController((controller: AbortController) =>
                dispatch(
                    fetchGlobalCatalogItems({
                        searchString: searchStr,
                        categoryId: categoryIdSearch,
                        abortController: controller,
                    }),
                ),
            );
        };

        const onDeleteCategoryChip = () => {
            dispatch(categoryActions.clearCategorySelection());
            navigate(getRouteGlobalCatalog(params.propertyId));
            dispatch(searchCatalogItemsActions.setGlobalCatItemSearchValue(''));
        };

        return (
            <Box>
                <SearchField
                    hideBorder={hideBorder}
                    placeholder={searchPlaceholder}
                    value={searchValue}
                    setValue={onSetValue}
                    chipLabel={getCategorySelectChipLabel()}
                    onChipDelete={onDeleteCategoryChip}
                    isActive={isActive}
                />
            </Box>
        );
    },
);
