/* eslint-disable max-len */
import { Box, Button, Dialog, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
    getSelectedCatalogItem,
    getSelectedCatalogItems,
} from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import {
    getSelectedProperty,
    getSelectedPropertyCatalogId,
} from 'entities/Property/model/selectors/PropertySelectors';
import {
    getRemoveCatalogItemDialog,
    getRemoveCatalogItemIsLoading,
    getRemoveCatalogItemIsSingle,
} from 'features/removeCatalogItem/model/selectors/removeCatalogItemSelectors';
import { removeCatalogItemService } from 'features/removeCatalogItem/model/services/removeCatalogItemService';
import { removeCatalogItemActions } from 'features/removeCatalogItem/model/slices/RemoveCatalogItemSlice';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { DialogBoxContent } from 'shared/ui/DialogBoxContent/DialogBoxContent';
import { Loader } from 'shared/ui/Loader/Loader';

export const RemoveCatalogItemDialog = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isOpened = useSelector(getRemoveCatalogItemDialog);
    const isLoading = useSelector(getRemoveCatalogItemIsLoading);
    const selectedItems = useSelector(getSelectedCatalogItems);
    const selectedItem = useSelector(getSelectedCatalogItem);
    const selectedCatalogId = useSelector(getSelectedPropertyCatalogId);
    const isSingleDelete = useSelector(getRemoveCatalogItemIsSingle);
    const selectedProperty = useSelector(getSelectedProperty);

    const qtyDeleteItems = useMemo(
        () => Object.keys(selectedItems).length,
        [selectedItems],
    );

    const onClose = () => {
        dispatch(removeCatalogItemActions.setShowDialog(false));
    };

    const onSubmit = async () => {
        const idOfSelectedItem = (selectedItem as any)?.uuid;
        const data = {
            items:
                isSingleDelete && idOfSelectedItem
                    ? [
                          {
                              itemUuid: idOfSelectedItem,
                          },
                      ]
                    : Object.values(selectedItems).map((item) => ({
                          itemUuid: item.uid,
                      })),
        };
        await dispatch(
            removeCatalogItemService({
                catalogId: selectedCatalogId,
                data,
                isSingle: Boolean(isSingleDelete),
            }),
        );
    };

    return (
        isOpened && (
            <Dialog open onClose={onClose}>
                <DialogBoxContent
                    onClose={onClose}
                    buttons={
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            width="100%"
                        >
                            <Button onClick={onClose}>{t('Cancel')}</Button>
                            <Button
                                disabled={isLoading}
                                startIcon={
                                    isLoading && <Loader color="inherit" />
                                }
                                onClick={onSubmit}
                                variant="contained"
                                color="error"
                            >
                                {`Remove ${isSingleDelete ? 'item' : 'items'}`}
                            </Button>
                        </Box>
                    }
                >
                    <Box p="0 24px" maxWidth="400px">
                        <Typography typography={'poppins.subtitle2'} mb="18px">
                            {isSingleDelete
                                ? t('Remove Item from Property Catalog')
                                : t('Remove Items from Property Catalog')}
                        </Typography>
                        {isSingleDelete ? (
                            <Typography typography={'openSans.body2'} mb="18px">
                                {`Are you sure you want to remove the selected
                                item from the `}
                                <strong
                                    style={{ whiteSpace: 'nowrap' }}
                                >{`${selectedProperty?.propertyNameLegal}`}</strong>
                                {` catalog?
                                The item will no longer be available to order
                                for this property.`}
                            </Typography>
                        ) : (
                            <Typography typography={'openSans.body2'} mb="18px">
                                {`Are you sure you want to remove ${qtyDeleteItems} items from the `}
                                <strong
                                    style={{ whiteSpace: 'nowrap' }}
                                >{`${selectedProperty?.propertyNameLegal}`}</strong>
                                {` catalog?
                                These items will no longer be available to order
                                for this property.`}
                            </Typography>
                        )}
                        <Typography typography={'openSans.body2'} mb="18px">
                            {`You can re-add ${
                                isSingleDelete ? 'it' : 'them'
                            } later from the global catalog.`}
                        </Typography>
                    </Box>
                </DialogBoxContent>
            </Dialog>
        )
    );
};
