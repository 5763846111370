import {
    Accordion,
    AccordionDetails,
    Box,
    Skeleton,
    alpha,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getDataFromOrder } from 'entities/Order';
import {
    getSelectedOrder,
    getSelectedOrderIsLoading,
} from 'entities/Order/model/selectors/ordersSelectors';
import { PropertyCardRow } from 'entities/Property';
import { getPropertyItem } from 'features/fetchPropertyById/model/selectors/fetchPropertyByIdSelectors';
import { useFeatureFlag } from 'shared/lib/hooks/useFeatureFlag';
import { AccordionHeader } from 'shared/ui/AccordionHeader/AccordionHeader';

export const OrderViewPageDetails = () => {
    const { t } = useTranslation();
    const renovationEnabled = useFeatureFlag('renovation_enabled');
    const currentProperty = useSelector(getPropertyItem);
    const isLoadingDetails = useSelector(getSelectedOrderIsLoading);
    const order = useSelector(getSelectedOrder);

    const {
        creatorName,
        createdAtDay,
        createdAtTime,
        updatedAtDay,
        updatedAtTime,
        lastUpdatedBy,
        orderType,
    } = getDataFromOrder(order);

    const createdTime = `${createdAtDay} ${createdAtTime}`;
    const updatedTime = `${updatedAtDay} ${updatedAtTime}`;

    return (
        <Accordion
            sx={(theme) => ({
                bgcolor: alpha(theme.palette.grey[500], 0.5),
                borderRadius: '12px',
            })}
            data-testid="order-details"
        >
            <AccordionHeader title={t('Details')} />
            <AccordionDetails sx={{ p: '12px' }}>
                {!isLoadingDetails ? (
                    <Box>
                        <PropertyCardRow
                            name={t('Property')}
                            value={currentProperty?.propertyNameLegal}
                            bordered
                        />
                        {renovationEnabled && (
                            <PropertyCardRow
                                name={t('Type')}
                                value={orderType}
                                bordered
                            />
                        )}
                        <PropertyCardRow
                            name={t('Created')}
                            value={createdTime}
                            bordered
                        />

                        <PropertyCardRow
                            name={t('Created By')}
                            value={creatorName}
                            bordered
                        />

                        <PropertyCardRow
                            name={t('Updated')}
                            value={updatedTime}
                            bordered
                        />
                        <PropertyCardRow
                            name={t('Updated By')}
                            value={lastUpdatedBy}
                            bordered
                        />
                    </Box>
                ) : (
                    <Box display="flex" flexDirection="column" gap="2px">
                        <Skeleton variant="rounded" height="52px" />
                        <Skeleton variant="rounded" height="52px" />
                        <Skeleton variant="rounded" height="52px" />
                    </Box>
                )}
            </AccordionDetails>
        </Accordion>
    );
};
