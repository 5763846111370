import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CatalogItem } from 'entities/CatalogItem/model/types/CatalogItemSchema';
import { OrderStatuses } from 'entities/Order/model/consts/orderStatuses';
import { getSelectedOrderIsLoading } from 'entities/Order/model/selectors/ordersSelectors';
import { UserRole, getUserRole } from 'entities/User';
import { getUserCanSeePrices } from 'entities/User/model/selectors/userPermissionsSeletors';
import { OrderInventoryTableRowSkeleton } from 'widgets/InventoryTable/ui';

import { OrderGroupedItemsTableRow } from './OrderGroupedItemsTableRow';

interface OrderGroupedItemsContainerProps {
    items: CatalogItem[];
    orderStatus: OrderStatuses;
}

export const OrderGroupedItemsTable = ({
    items,
    orderStatus,
}: OrderGroupedItemsContainerProps) => {
    const userRole = useSelector(getUserRole);
    const canSeePrices = useSelector(getUserCanSeePrices);
    const isLoading = useSelector(getSelectedOrderIsLoading);

    const isInReview = orderStatus === OrderStatuses.IN_REVIEW;
    const isHidePrice = userRole === UserRole.MAINTENANCE_TECHNICIAN;

    const { t } = useTranslation();

    return !isLoading ? (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    padding: '0 16px 4px',
                    gap: '24px',
                }}
            >
                <Box sx={{ flex: '52%' }}>
                    <Typography
                        typography="openSans.captionMedium"
                        data-testclass="order-item-title-label"
                    >
                        {t('Item')}
                    </Typography>
                </Box>
                {!isInReview && (
                    <>
                        <Box
                            sx={{ flex: '23%' }}
                            width={!isInReview ? '' : '140px'}
                        >
                            <Typography
                                typography="openSans.captionMedium"
                                align="center"
                                data-testclass="order-items-qty-label"
                            >
                                {t('Qty')}
                            </Typography>
                        </Box>
                        {canSeePrices && !isHidePrice && (
                            <Box sx={{ flex: '15%' }}>
                                <Typography
                                    typography="openSans.captionMedium"
                                    align="right"
                                    data-testclass="order-items-total-label"
                                >
                                    {t('Total')}
                                </Typography>
                            </Box>
                        )}
                    </>
                )}
            </Box>
            <Box>
                {items.map((item) => (
                    <OrderGroupedItemsTableRow key={item.id} item={item} />
                ))}
            </Box>
        </Box>
    ) : (
        <>
            <OrderInventoryTableRowSkeleton />
            <OrderInventoryTableRowSkeleton />
            <OrderInventoryTableRowSkeleton />
        </>
    );
};
