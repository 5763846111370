import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { addItemsToOrderService } from '../services/addItemsToOrderService';
import { AddItemsToOrderSchema } from '../types/addItemsToOrderSchema';

const initialState: AddItemsToOrderSchema = {
    isLoading: false,
};

export const addItemsToOrderSlice = createSlice({
    name: 'addItemsToOrder',
    initialState,
    reducers: {
        setIsLoadingAddItemsToOrder: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.isLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addItemsToOrderService.pending, (state) => {
                state.error = undefined;
            })
            .addCase(addItemsToOrderService.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(addItemsToOrderService.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { actions: addItemsToOrderActions } = addItemsToOrderSlice;
export const { reducer: addItemsToOrderReducer } = addItemsToOrderSlice;
