import { debounce } from '@mui/material';
import { useField } from 'formik';
import { useCallback } from 'react';
import { ColorChangeHandler, ColorResult } from 'react-color';

import ColorPicker from './ColorPicker';

const FormikColorPicker = ({ name }: { name: string }) => {
    const [{ value }, { error }, { setValue }] = useField(name);

    const onValueChange = useCallback((color: ColorResult['hex']) => {
        setValue(color);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange: ColorChangeHandler = useCallback(
        (color) => {
            onValueChange(color.hex);
        },
        [onValueChange],
    );

    return (
        <ColorPicker
            color={value}
            error={error}
            onChange={debounce(onChange, 1000)}
        />
    );
};

export default FormikColorPicker;
