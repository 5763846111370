import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkConfig } from 'app/providers/StoreProvider';
import { catalogItemActions } from 'entities/CatalogItem/model/slices/CatalogItemSlice';

import {
    AddItemsToOrderServiceParams,
    AddItemsToOrderServiceResponse,
} from '../types/addItemsToOrderSchema';

export const addItemsToOrderService = createAsyncThunk<
    AddItemsToOrderServiceResponse,
    AddItemsToOrderServiceParams,
    ThunkConfig<string>
>('order/addItemsToOrderService', async ({ orderId, data }, thunkApi) => {
    const { extra, rejectWithValue, dispatch } = thunkApi;

    try {
        const response = await extra.api.post<AddItemsToOrderServiceResponse>(
            `/v1/orders/${orderId}/items`,
            data,
        );

        dispatch(catalogItemActions.setSelection(response.data.items));

        return response.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
