/* eslint-disable max-len */
import { Button } from '@mui/material';
import { ReactNode, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { OrderStatuses } from 'entities/Order/model/consts/orderStatuses';
import { changeOrderStatusService } from 'features/changeOrderStatus/model/services/changeOrderStatusService';
import { getRouteOrders } from 'shared/const/router';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import BlockPurchaseOrderModal from 'shared/ui/BlockPurchaseOrderModal/BlockPurchaseOrderModal';
import BlockRejectOrderModal from 'shared/ui/BlockRejectOrderModal/BlockRejectOrderModal';
import { Loader } from 'shared/ui/Loader/Loader';

interface ChangeOrderStatusButtonProps {
    children: ReactNode;
    newStatus: OrderStatuses;
    messageOnSuccess: string;
    orderId: string;
    variant?: 'text' | 'contained' | 'outlined';
    disabled?: boolean;
    isRequiredPoNumberForOrder?: boolean;
}

export const ChangeOrderStatusButton = memo(
    ({
        newStatus,
        children,
        variant,
        orderId,
        messageOnSuccess,
        disabled,
        isRequiredPoNumberForOrder,
    }: ChangeOrderStatusButtonProps) => {
        const { t } = useTranslation();
        const dispatch = useAppDispatch();
        const navigate = useNavigate();

        const [isLoading, setIsLoading] = useState(false);
        const [showBlockPurchaseModal, setShowBlockPurchaseModal] =
            useState(false);
        const [showBlockRejectModal, setShowBlockRejectModal] = useState(false);

        const onCloseBlockPurchaseModal = () =>
            setShowBlockPurchaseModal(false);

        const onCloseBlockRejectModal = () => setShowBlockRejectModal(false);

        const submitChangeOrderStatus = useCallback(async () => {
            setIsLoading(true);

            const response = await dispatch(
                changeOrderStatusService({
                    orderId,
                    status: newStatus,
                }),
            );

            if (response.meta.requestStatus !== 'rejected') {
                toast(messageOnSuccess);
                navigate(getRouteOrders());
            } else {
                toast(response?.payload as string);
            }
            onCloseBlockPurchaseModal();
            onCloseBlockRejectModal();
            setIsLoading(false);
        }, [dispatch, messageOnSuccess, navigate, newStatus, orderId]);

        const onChangeOrderStatus = useCallback(async () => {
            if (isLoading) return;
            if (isRequiredPoNumberForOrder) {
                setShowBlockPurchaseModal(true);
                return;
            }
            if (disabled) return;
            if (newStatus === OrderStatuses.REJECTED) {
                setShowBlockRejectModal(true);
                return;
            }
            submitChangeOrderStatus();
        }, [
            disabled,
            isLoading,
            isRequiredPoNumberForOrder,
            newStatus,
            submitChangeOrderStatus,
        ]);

        return (
            <>
                <Button
                    startIcon={isLoading && <Loader color="inherit" />}
                    className={disabled || isLoading ? 'Mui-disabled' : ''}
                    style={{
                        pointerEvents: 'auto',
                    }}
                    variant={variant || 'outlined'}
                    onClick={onChangeOrderStatus}
                >
                    {children}
                </Button>
                <BlockPurchaseOrderModal
                    showBlockModal={showBlockPurchaseModal}
                    onCloseBlockModal={onCloseBlockPurchaseModal}
                />
                <BlockRejectOrderModal
                    showBlockModal={showBlockRejectModal}
                    onCloseBlockModal={onCloseBlockRejectModal}
                    onSubmitModal={submitChangeOrderStatus}
                    isLoading={isLoading}
                />
            </>
        );
    },
);
