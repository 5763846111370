import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { NavigationBlockModalSchema } from '../types/NavigationBlockModalSchema';

const initialState: NavigationBlockModalSchema = {
    showDialog: false,
    navBlocked: false,
};

export const NavigationBlockModalSlice = createSlice({
    name: 'NavigationBlockModal',
    initialState,
    reducers: {
        setNavigationBlocked: (state, action: PayloadAction<boolean>) => {
            state.navBlocked = action.payload;
        },
        setShowDialog: (state, action: PayloadAction<boolean>) => {
            if (action.payload && !state.navBlocked) return;
            state.showDialog = action.payload;
        },
    },
});

export const { actions: navigationBlockModalActions } =
    NavigationBlockModalSlice;
export const { reducer: navigationBlockModalReducer } =
    NavigationBlockModalSlice;
