import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkConfig } from 'app/providers/StoreProvider';

export const getSelectedLabels = createAsyncThunk<
    Blob,
    { catalogId: string; selectedItemsIds: string[] },
    ThunkConfig<any>
>(
    'catalog/getSelectedLabels',
    async ({ catalogId, selectedItemsIds }, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi;
        try {
            const response = await extra.api.get<Blob>(
                `/v1/catalogs/${catalogId}/labels?items=${selectedItemsIds.join(
                    ',',
                )}`,
                {
                    responseType: 'blob',
                },
            );

            return response?.data;
        } catch (e) {
            return rejectWithValue('error');
        }
    },
);
