import { useCallback } from 'react';

import { debounce } from 'shared/lib/lodash/lodash';

export function useDebouncedCallback(
    callback: (...params: Array<any>) => void | Promise<void>,
) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(debounce(callback, 500), []);
}
