import { FliptEvaluationClient } from '@flipt-io/flipt-client-browser';
import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react';

interface FliptContextType {
    fliptClient: FliptEvaluationClient | null;
}

const FliptContext = createContext<FliptContextType | undefined>(undefined);

export const useFlipt = (): FliptEvaluationClient | null => {
    const context = useContext(FliptContext);
    if (!context) {
        throw new Error('useFlipt must be used within a FliptProvider');
    }
    return context.fliptClient;
};

interface FliptProviderProps {
    children: ReactNode;
}

export const FliptProvider = ({ children }: FliptProviderProps) => {
    const [fliptClient, setFliptClient] =
        useState<FliptEvaluationClient | null>(null);

    useEffect(() => {
        const initializeClient = async () => {
            const client = await FliptEvaluationClient.init('default', {
                url: 'https://ff.re-ops.io/',
            });
            setFliptClient(client);
        };

        initializeClient();
    }, []);

    return (
        <FliptContext.Provider value={{ fliptClient }}>
            {children}
        </FliptContext.Provider>
    );
};
