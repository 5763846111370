import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderStatuses } from 'entities/Order/model/consts/orderStatuses';

import { ChangeOrderStatusButton } from './ChangeOrderStatusButton';

interface RejectOrderButtonProps {
    orderId: string;
    isNoItemsInOrder: boolean;
}

export const RejectOrderButton = memo(
    ({ orderId, isNoItemsInOrder }: RejectOrderButtonProps) => {
        const { t } = useTranslation();

        return (
            <ChangeOrderStatusButton
                newStatus={OrderStatuses.REJECTED}
                orderId={orderId}
                messageOnSuccess={t('Order rejected')}
                disabled={isNoItemsInOrder}
            >
                {t('Reject')}
            </ChangeOrderStatusButton>
        );
    },
);
