import { DeleteForeverRounded } from '@mui/icons-material';
import { Box, Typography, alpha } from '@mui/material';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { removeCatalogItemActions } from 'features/removeCatalogItem/model/slices/RemoveCatalogItemSlice';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { useInitialEffect } from 'shared/lib/hooks/useInitialEffect';

import { RemoveCatalogItemDialog } from '../RemoveCatalogItemDialog/RemoveCatalogItemDialog';

interface RemoveCatalogItemProps {
    isSingleItem?: boolean;
}

export const RemoveCatalogItem = memo(
    ({ isSingleItem }: RemoveCatalogItemProps) => {
        const { t } = useTranslation();
        const dispatch = useAppDispatch();

        useInitialEffect(() => {
            dispatch(removeCatalogItemActions.setIsSingle(isSingleItem));
        });

        useEffect(() => {
            dispatch(removeCatalogItemActions.setIsSingle(isSingleItem));
        }, [dispatch, isSingleItem]);

        const onShowDeleteDialog = () => {
            dispatch(removeCatalogItemActions.setShowDialog(true));
        };

        return (
            <>
                <Box
                    display="flex"
                    sx={{ cursor: 'pointer' }}
                    onClick={onShowDeleteDialog}
                >
                    <DeleteForeverRounded
                        sx={(theme) => ({
                            color: alpha(theme.palette.text.disabled, 0.6),
                        })}
                    />
                    <Typography
                        sx={(theme) => ({
                            color: alpha(theme.palette.text.disabled, 0.6),
                        })}
                        typography="openSans.subtitle2"
                        ml="4px"
                    >
                        {t('Remove')}
                    </Typography>
                </Box>
                <RemoveCatalogItemDialog />
            </>
        );
    },
);
