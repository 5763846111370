import { UserRole } from 'entities/User';
import { AccessDeniedPage } from 'pages/AccessDeniedPage';
import { AddVendorPage } from 'pages/AddVendorPage';
import { AuthRedirectPage } from 'pages/AuthRedirectPage';
import { ForbiddenPage } from 'pages/ForbiddenPage';
import { InventoryPage } from 'pages/InventoryPage';
import InventoryCatalogPage from 'pages/InventoryPage/ui/InventoryCatalogPage/InventoryCatalogPage';
import { LoginRedirectPage } from 'pages/LoginRedirectPage';
import { MainPage } from 'pages/MainPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { OnboardMobileUserPage } from 'pages/OnboardMobileUserPage';
import { OrderAddItemPage } from 'pages/OrderAddItemPage';
import { OrderAddItemPageCatalog } from 'pages/OrderAddItemPage/ui/OrderAddItemPage/OrderAddItemPageCatalog';
import { OrderViewPage } from 'pages/OrderViewPage';
import { OrdersPage } from 'pages/OrdersPage';
import { PrivacyPolicyEmbededPage } from 'pages/PrivacyPolicyEmbededPage';
import { PrivacyPolicyPage } from 'pages/PrivacyPolicyPage';
import { ProductSourcingDefinerOutlet } from 'pages/ProductSourcing';
import { ProfilePage } from 'pages/ProfilePage';
import { PropertiesPage } from 'pages/PropertiesPage';
import { PropertiesItem } from 'pages/PropertiesPage/ui/PropertyItem';
import {
    PropertyCatManagementPage,
    PropertyCatalogPage,
} from 'pages/PropertyCatalogPage';
import { PropertyInfoPage } from 'pages/PropertyInfoPage';
import { ReportsMainPage } from 'pages/ReportsMainPage';
import { ReportsPageCategory } from 'pages/ReportsPageByCategory';
import { SettingsPage } from 'pages/SettingsMainPage';
import { SupplierPage } from 'pages/SupplierPage';
import { TermsOfServiceEmbededPage } from 'pages/TermsOfServiceEmbededPage';
import { TermsOfServicePage } from 'pages/TermsOfServicePage';
import { VendorsMainPage } from 'pages/VendorsMainPage';
import {
    AppRoutes,
    getOnboardMobile,
    getRouteAccessDenied,
    getRouteAddVendors,
    getRouteAuth,
    getRouteForbidden,
    getRouteGlobalCatalog,
    getRouteGlobalCatalogItem,
    getRouteLogin,
    getRouteMain,
    getRouteMainReports,
    getRouteMainVendors,
    getRouteNotFound,
    getRouteOrderCatalogCategoriesItem,
    getRouteOrderCatalogInventoryItem,
    getRouteOrders,
    getRouteOrdersAddItems,
    getRouteOrdersAddItemsCategories,
    getRouteOrdersAddItemsInventory,
    getRouteOrdersTable,
    getRouteOrdersTableByStatus,
    getRouteOrdersView,
    getRouteOrdersViewItemDetailed,
    getRoutePrivacyPolicy,
    getRoutePrivacyPolicyEmbeded,
    getRouteProductSourcingPage,
    getRouteProfile,
    getRouteProperties,
    getRoutePropertiesCatalog,
    getRoutePropertiesCatalogInventory,
    getRoutePropertiesCatalogInventoryCategories,
    getRoutePropertiesCatalogInventoryCategoriesSelected,
    getRoutePropertiesCatalogInventoryItem,
    getRoutePropertiesCatalogItem,
    getRoutePropertiesCatalogSelected,
    getRoutePropertiesGlobalCatalogSelected,
    getRoutePropertiesItem,
    getRouteReportsByCategory,
    getRouteSettings,
    getRouteTermsOfService,
    getRouteTermsOfServiceEmbeded,
    getRouteVendorsSupplierPage,
} from 'shared/const/router';
import { AppRoutesProps } from 'shared/types/router';
import { OrderInventoryTable } from 'widgets/InventoryTable/ui/OrderInventoryTable/OrderInventoryTable';
import { OrderViewItemDetailed } from 'widgets/OrderViewItemDetailed';
import { OrdersTable } from 'widgets/OrdersTable';
import { OrdersTableGrid } from 'widgets/OrdersTable/ui/OrdersTableGrid';
import {
    PropertyCatalogItemDataDefinerOutlet,
    PropertyCatalogItemsOutlet,
} from 'widgets/PropertyCatalog';

export const routeConfig: Partial<Record<AppRoutes, AppRoutesProps>> = {
    [AppRoutes.PROPERTIES]: {
        path: getRouteProperties(),
        element: <PropertiesPage />,
        roles: [
            UserRole.PMC_ADMIN,
            UserRole.PROPERTY_MANAGER,
            UserRole.MAINTENANCE_TECHNICIAN,
            UserRole.MAINTENANCE_SUPERVISOR,
        ],
        authOnly: true,
        nested: {
            [AppRoutes.PROPERTIES_ITEM]: {
                path: getRoutePropertiesItem(':propertyId'),
                element: <PropertiesItem />,
                nested: {
                    [AppRoutes.PROPERTIES_INFO]: {
                        path: getRoutePropertiesItem(':propertyId'),
                        element: <PropertyInfoPage />,
                    },
                    [AppRoutes.PROPERTIES_CATALOG]: {
                        path: getRoutePropertiesCatalog(':propertyId'),
                        element: <PropertyCatalogPage />,
                        nested: {
                            [AppRoutes.PROPERTIES_CATALOG_MANAGEMENT]: {
                                path: getRoutePropertiesCatalog(':propertyId'),
                                element: <PropertyCatManagementPage />,
                                nested: {
                                    [AppRoutes.PROPERTIES_CATALOG_ITEMS]: {
                                        path: getRoutePropertiesCatalogSelected(
                                            ':propertyId',
                                            ':categoryId',
                                        ),
                                        element: <PropertyCatalogItemsOutlet />,
                                    },
                                    [AppRoutes.PROPERTIES_CATALOG_ITEM]: {
                                        path: getRoutePropertiesCatalogItem(
                                            ':propertyId',
                                            ':categoryId',
                                            ':itemId',
                                        ),
                                        element: (
                                            <PropertyCatalogItemDataDefinerOutlet />
                                        ),
                                    },
                                },
                            },
                            [AppRoutes.PROPERTIES_GLOBAL_CATALOG]: {
                                path: getRouteGlobalCatalog(':propertyId'),
                                element: <PropertyCatManagementPage />,
                                nested: {
                                    [AppRoutes.PROPERTIES_GLOBAL_CATALOG_ITEMS]:
                                        {
                                            path: getRoutePropertiesGlobalCatalogSelected(
                                                ':propertyId',
                                                ':categoryId',
                                            ),
                                            element: (
                                                <PropertyCatalogItemsOutlet />
                                            ),
                                        },
                                    [AppRoutes.PROPERTIES_GLOBAL_CATALOG_ITEM]:
                                        {
                                            path: getRouteGlobalCatalogItem(
                                                ':propertyId',
                                                ':categoryId',
                                                ':itemId',
                                            ),
                                            element: (
                                                <PropertyCatalogItemDataDefinerOutlet />
                                            ),
                                        },
                                },
                            },
                            [AppRoutes.PROPERTIES_CATALOG_INVENTORY]: {
                                path: getRoutePropertiesCatalogInventory(
                                    ':propertyId',
                                ),
                                element: <InventoryPage />,
                                nested: {
                                    [AppRoutes.PROPERTIES_CATALOG_INVENTORY_ITEM]:
                                        {
                                            path: getRoutePropertiesCatalogInventoryItem(
                                                ':propertyId',
                                                ':itemId',
                                            ),
                                            element: (
                                                <PropertyCatalogItemDataDefinerOutlet />
                                            ),
                                        },
                                },
                            },
                            [AppRoutes.PROPERTIES_CATALOG_INVENTORY_CATEGORIES]:
                                {
                                    path: getRoutePropertiesCatalogInventoryCategories(
                                        ':propertyId',
                                    ),
                                    element: <InventoryCatalogPage />,
                                    nested: {
                                        [AppRoutes.PROPERTIES_CATALOG_INVENTORY_CATEGORIES_ITEMS]:
                                            {
                                                path: getRoutePropertiesCatalogInventoryCategoriesSelected(
                                                    ':propertyId',
                                                    ':categoryId',
                                                ),
                                                element: (
                                                    <PropertyCatalogItemsOutlet />
                                                ),
                                            },
                                    },
                                },
                        },
                    },
                },
            },
        },
    },
    [AppRoutes.ORDERS]: {
        path: getRouteOrders(),
        element: <OrdersPage />,
        roles: [
            UserRole.PMC_ADMIN,
            UserRole.PROPERTY_MANAGER,
            UserRole.MAINTENANCE_TECHNICIAN,
            UserRole.MAINTENANCE_SUPERVISOR,
        ],
        authOnly: true,
        nested: {
            [AppRoutes.ORDERS_TABLE]: {
                path: getRouteOrdersTable(':propertyId'),
                element: <OrdersTable />,
                nested: {
                    [AppRoutes.ORDERS_TABLE_STATUS]: {
                        path: getRouteOrdersTableByStatus(
                            ':propertyId',
                            ':orderStatus',
                        ),
                        element: <OrdersTableGrid />,
                    },
                },
            },
        },
    },
    [AppRoutes.ORDERS_ADD_ITEMS]: {
        path: getRouteOrdersAddItems(),
        element: <OrderAddItemPage />,
        roles: [
            UserRole.PMC_ADMIN,
            UserRole.PROPERTY_MANAGER,
            UserRole.MAINTENANCE_TECHNICIAN,
            UserRole.MAINTENANCE_SUPERVISOR,
        ],
        nested: {
            [AppRoutes.ORDERS_ADD_ITEMS_CATEGORIES]: {
                path: getRouteOrdersAddItemsCategories(),
                element: <OrderAddItemPageCatalog />,
                nested: {
                    [AppRoutes.ORDERS_ADD_ITEMS_CATEGORIES]: {
                        path: getRouteOrdersAddItemsCategories(),
                        element: <PropertyCatalogItemsOutlet />,
                    },
                    [AppRoutes.ORDERS_ADD_ITEMS_CATEGORIES_DETAILED]: {
                        path: getRouteOrderCatalogCategoriesItem(':itemId'),
                        element: <PropertyCatalogItemDataDefinerOutlet />,
                    },
                },
            },
            [AppRoutes.ORDERS_ADD_ITEMS_INVENTORY]: {
                path: getRouteOrdersAddItemsInventory(),
                element: <OrderInventoryTable />,
                nested: {
                    [AppRoutes.ORDERS_ADD_ITEMS_INVENTORY_DETAILED]: {
                        path: getRouteOrderCatalogInventoryItem(':itemId'),
                        element: <PropertyCatalogItemDataDefinerOutlet />,
                    },
                },
            },
        },
        authOnly: true,
    },
    [AppRoutes.ORDERS_VIEW]: {
        path: getRouteOrdersView(':orderId'),
        element: <OrderViewPage />,
        roles: [
            UserRole.PMC_ADMIN,
            UserRole.PROPERTY_MANAGER,
            UserRole.MAINTENANCE_TECHNICIAN,
            UserRole.MAINTENANCE_SUPERVISOR,
        ],
        authOnly: true,
        nested: {
            [AppRoutes.ORDERS_VIEW_ITEM_DETAILED]: {
                path: getRouteOrdersViewItemDetailed(':orderId', ':itemId'),
                element: <OrderViewItemDetailed />,
            },
        },
    },
    [AppRoutes.VENDORS]: {
        path: getRouteMainVendors(':propertyId'),
        element: <VendorsMainPage />,
        roles: [
            UserRole.PMC_ADMIN,
            UserRole.PROPERTY_MANAGER,
            UserRole.MAINTENANCE_TECHNICIAN,
            UserRole.MAINTENANCE_SUPERVISOR,
        ],
        authOnly: true,
    },
    [AppRoutes.VENDORS_SUPPLIER_PAGE]: {
        path: getRouteVendorsSupplierPage(':supplierId', ':propertyId'),
        element: <SupplierPage />,
        roles: [
            UserRole.PMC_ADMIN,
            UserRole.PROPERTY_MANAGER,
            UserRole.MAINTENANCE_TECHNICIAN,
            UserRole.MAINTENANCE_SUPERVISOR,
        ],
        authOnly: true,
    },
    [AppRoutes.ADD_VENDORS_PAGE]: {
        path: getRouteAddVendors(':propertyId'),
        element: <AddVendorPage />,
        roles: [UserRole.PMC_ADMIN, UserRole.PROPERTY_MANAGER],
        authOnly: true,
    },
    [AppRoutes.REPORTS]: {
        path: getRouteMainReports(),
        element: <ReportsMainPage />,
        roles: [
            UserRole.PMC_ADMIN,
            UserRole.PROPERTY_MANAGER,
            UserRole.MAINTENANCE_SUPERVISOR,
        ],
        authOnly: true,
    },
    [AppRoutes.REPORTS_BY_CATEGORY]: {
        path: getRouteReportsByCategory(),
        element: <ReportsPageCategory />,
        roles: [
            UserRole.PMC_ADMIN,
            UserRole.PROPERTY_MANAGER,
            UserRole.MAINTENANCE_SUPERVISOR,
        ],
        authOnly: true,
    },
    [AppRoutes.SETTINGS]: {
        path: getRouteSettings(),
        element: <SettingsPage />,
        roles: [UserRole.PMC_ADMIN, UserRole.PROPERTY_MANAGER],
        authOnly: true,
    },
    [AppRoutes.PRODUCT_SOURCING_PAGE]: {
        path: getRouteProductSourcingPage(':propertyId', ':orderType'),
        element: <ProductSourcingDefinerOutlet />,
        roles: [UserRole.PMC_ADMIN, UserRole.PROPERTY_MANAGER],
        authOnly: true,
    },
    [AppRoutes.MAIN]: {
        path: getRouteMain(),
        element: <MainPage />,
    },
    [AppRoutes.ONBOARD_MOBILE]: {
        path: getOnboardMobile(),
        element: <OnboardMobileUserPage />,
        // temp
        // will be added real role
        // roles: [UserRole.PREONBOARD],
    },
    [AppRoutes.LOGIN]: {
        path: getRouteLogin(),
        element: <LoginRedirectPage />,
    },
    [AppRoutes.AUTH]: {
        path: getRouteAuth(),
        element: <AuthRedirectPage />,
    },
    [AppRoutes.PROFILE]: {
        path: getRouteProfile(),
        element: <ProfilePage />,
        authOnly: true,
    },
    [AppRoutes.PRIVACY_POLICY]: {
        path: getRoutePrivacyPolicy(),
        element: <PrivacyPolicyPage />,
    },
    [AppRoutes.TERMS_OF_SERVICE]: {
        path: getRouteTermsOfService(),
        element: <TermsOfServicePage />,
    },
    [AppRoutes.PRIVACY_POLICY_EMBEDED]: {
        path: getRoutePrivacyPolicyEmbeded(),
        element: <PrivacyPolicyEmbededPage />,
    },
    [AppRoutes.TERMS_OF_SERVICE_EMBEDED]: {
        path: getRouteTermsOfServiceEmbeded(),
        element: <TermsOfServiceEmbededPage />,
    },
    // error pages
    [AppRoutes.FORBIDDEN]: {
        authOnly: true,
        path: getRouteForbidden(),
        element: <ForbiddenPage />,
    },
    [AppRoutes.ACCESS_DENIED]: {
        path: getRouteAccessDenied(),
        element: <AccessDeniedPage />,
    },
    [AppRoutes.NOT_FOUND_PAGE]: {
        path: getRouteNotFound(),
        element: <NotFoundPage />,
    },
    // last
    [AppRoutes.NOT_FOUND]: {
        path: '*',
        element: <NotFoundPage />,
    },
};
