import { useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { fetchProperties } from 'entities/Property/model/services/fetchProperties/fetchProperties';
import { propertyActions } from 'entities/Property/model/slices/PropertySlice';
import { getRouteForbidden, getRoutePropertiesItem } from 'shared/const/router';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { useInitialEffect } from 'shared/lib/hooks/useInitialEffect';
import { isArray } from 'shared/lib/lodash/lodash';

import { Property } from '../types/PropertySchema';

export const useFetchProperties = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const initialPropertyIdParam = useRef(null);
    const navigateRef = useRef(null);

    useInitialEffect(() => {
        initialPropertyIdParam.current = params.propertyId;
        navigateRef.current = navigate;
    });

    const onFetchProperties = useCallback(
        async (isOrdersPage?: boolean) => {
            const propId = initialPropertyIdParam.current;
            const navigate = navigateRef.current;
            if (propId) {
                dispatch(propertyActions.setPropertyId(propId));
            }

            const response = await dispatch(fetchProperties());

            if (
                propId &&
                isArray(response.payload) &&
                !response.payload.find((prop: Property) => prop.id === propId)
            ) {
                navigate(getRouteForbidden());
                return null;
            }

            if (
                !isOrdersPage &&
                !propId &&
                isArray(response.payload) &&
                response.payload.length > 0
            ) {
                navigate(getRoutePropertiesItem(response.payload[0].id));
            }

            return response;
        },
        [dispatch],
    );

    return onFetchProperties;
};
