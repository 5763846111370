import { Box, Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
    OrderStatuses,
    OrderStatusesCamel,
    getOrderStatusForRoute,
    statusMapping,
    useGetOrderStatusTitle,
} from 'entities/Order/model/consts/orderStatuses';
import { getOrdersSearchParamsStatus } from 'entities/Order/model/selectors/ordersSelectors';
import { orderActions } from 'entities/Order/model/slices/OrderSlice';
import { getRouteOrdersTableByStatus } from 'shared/const/router';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { camelCase } from 'shared/lib/lodash/lodash';

import { getOrdersTableCounters } from '../model/selectors/catalogItemSelectors';
import { orderTableActions } from '../model/slices/OrderTableSlice';

// Reduce the big list of status counts down to just the statuses we display with the `statusMapping` table
const mapCountersToDisplayStatuses = (
    counters: Record<Partial<OrderStatusesCamel>, number>,
): Partial<Record<Partial<OrderStatusesCamel>, number>> => {
    return Object.keys(statusMapping).reduce((mappedCounters, group) => {
        const groupKey = camelCase(group) as OrderStatusesCamel;

        // Ensure we collect counts for all possible mapped statuses from `statusMapping`
        const mappedCount = statusMapping[group as OrderStatuses].reduce(
            (count, subStatus) => {
                const subStatusKey = camelCase(subStatus) as OrderStatusesCamel;
                return count + (counters?.[subStatusKey] || 0);
            },
            0,
        );

        mappedCounters[groupKey] = mappedCount;
        return mappedCounters;
    }, {} as Partial<Record<Partial<OrderStatusesCamel>, number>>);
};

export const OrdersTableTabs = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const counters = useSelector(getOrdersTableCounters);

    const mappedCounters = mapCountersToDisplayStatuses(counters);

    const currentStatus = useSelector(getOrdersSearchParamsStatus);
    const pendingApproval = useGetOrderStatusTitle(
        OrderStatuses.PENDING_APPROVAL,
    );
    const approved = useGetOrderStatusTitle(OrderStatuses.APPROVED);
    const draft = useGetOrderStatusTitle(OrderStatuses.DRAFT);
    const reject = useGetOrderStatusTitle(OrderStatuses.REJECTED);
    const inReview = useGetOrderStatusTitle(OrderStatuses.IN_REVIEW);
    const processing = useGetOrderStatusTitle(OrderStatuses.PROCESSING);
    const shipped = useGetOrderStatusTitle(OrderStatuses.SHIPPED);
    const invoiced = useGetOrderStatusTitle(OrderStatuses.INVOICED);
    const unableToFulfill = useGetOrderStatusTitle(
        OrderStatuses.UNABLE_TO_FULFILL,
    );

    const onTabChange = (
        event: SyntheticEvent<Element, Event>,
        value: OrderStatuses,
    ) => {
        dispatch(orderActions.setSearchParamsStatus(value));
        dispatch(orderTableActions.clearPaginationForTabChange());
        navigate(
            getRouteOrdersTableByStatus(
                params?.propertyId,
                getOrderStatusForRoute(value),
            ),
        );
    };

    const schema = useMemo(
        () => [
            {
                label: draft,
                value: OrderStatuses.DRAFT,
                key: OrderStatusesCamel.DRAFT,
                showCount: true,
            },
            {
                label: inReview,
                value: OrderStatuses.IN_REVIEW,
                key: OrderStatusesCamel.IN_REVIEW,
                showCount: true,
            },
            {
                label: pendingApproval,
                value: OrderStatuses.PENDING_APPROVAL,
                key: OrderStatusesCamel.PENDING_APPROVAL,
                showCount: true,
            },
            {
                label: approved,
                value: OrderStatuses.APPROVED,
                key: OrderStatusesCamel.APPROVED,
            },
            {
                label: reject,
                value: OrderStatuses.REJECTED,
                key: OrderStatusesCamel.REJECTED,
            },
            {
                label: processing,
                value: OrderStatuses.PROCESSING,
                key: OrderStatusesCamel.PROCESSING,
                showCount: true,
            },
            {
                label: shipped,
                value: OrderStatuses.SHIPPED,
                key: OrderStatusesCamel.SHIPPED,
                showCount: true,
            },
            {
                label: invoiced,
                value: OrderStatuses.INVOICED,
                key: OrderStatusesCamel.INVOICED,
            },
            {
                label: unableToFulfill,
                value: OrderStatuses.UNABLE_TO_FULFILL,
                key: OrderStatusesCamel.UNABLE_TO_FULFILL,
            },
        ],
        [
            approved,
            draft,
            inReview,
            pendingApproval,
            reject,
            processing,
            shipped,
            invoiced,
            unableToFulfill,
        ],
    );

    return (
        <Box
            mb="24px"
            borderBottom="1px solid"
            borderColor="grey.500"
            data-testid="OrderTableTabs"
        >
            <Tabs value={currentStatus} onChange={onTabChange}>
                {schema.map((item) => {
                    const count = mappedCounters?.[item.key];
                    const getLabelWithCounter = (label: string) => {
                        let currentCount;
                        if (count) {
                            currentCount = `(${count})`;
                            if (count > 99) {
                                currentCount = '(99+)';
                            }
                        } else {
                            currentCount = '(0)';
                        }
                        return `${label} ${
                            item?.showCount ? currentCount : ''
                        }`;
                    };

                    const label = getLabelWithCounter(item.label);

                    return (
                        <Tab
                            key={item.key}
                            label={label}
                            value={item.value}
                            data-testid="OrderTableTabs.Tab"
                        />
                    );
                })}
            </Tabs>
        </Box>
    );
};
