import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkConfig } from 'app/providers/StoreProvider';
import { CatalogItemInOrder } from 'entities/CatalogItem/model/types/CatalogItemSchema';

import { EditItemsInOrderRequestParams } from '../../types/editItemsInOrderSchema';

export const editOrderItem = createAsyncThunk<
    CatalogItemInOrder,
    EditItemsInOrderRequestParams,
    ThunkConfig<string>
>(
    'order/editOrderItem',
    async ({ orderId, itemId, qty, selectedProposal }, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.patch<CatalogItemInOrder>(
                `/v1/orders/${orderId}/items/${itemId}`,
                {
                    qty,
                    selectedProposal,
                },
            );

            return response.data;
        } catch (e) {
            return rejectWithValue('error');
        }
    },
);
