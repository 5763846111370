import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkConfig } from 'app/providers/StoreProvider';
import { UserProfile } from 'entities/User';

export const updateUser = createAsyncThunk<
    UserProfile,
    UserProfile,
    ThunkConfig<string>
>('user/updateUser', async (formData, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.patch<UserProfile>(
            `/v1/users/edit`,
            formData,
        );

        if (!response.data) {
            throw new Error();
        }

        return response.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
