import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { fetchOrders } from 'entities/Order/model/services/fetchOrders/fetchOrders';
import { Order } from 'entities/Order/model/types/OrderSchema';
import { remove } from 'shared/lib/lodash/lodash';

import { fetchOrdersCounters } from '../services/fetchOrdersCounters/fetchOrdersCounters';
import { OrderTableSchema } from '../types/OrderTableSchema';

const initialState: OrderTableSchema = {
    isInitialLoaded: false,
    dataList: [],
    listIsLoading: false,
    error: null,
    pagination: {
        pageSize: 10,
        currentPage: 0,
        total: 0,
    },
    sorting: {
        column: 'order_number',
        direction: 'desc',
    },
    counters: null,
    countersAreLoading: false,
};

export const OrderTableSlice = createSlice({
    name: 'OrderTable',
    initialState,
    reducers: {
        setInitialLoaded: (state, action: PayloadAction<boolean>) => {
            state.isInitialLoaded = action.payload;
        },
        setCurrentPage(state, action: PayloadAction<number>) {
            state.pagination = {
                ...state.pagination,
                currentPage: action.payload,
            };
        },
        setPageSize(state, action: PayloadAction<number>) {
            state.pagination = {
                ...state.pagination,
                pageSize: action.payload,
            };
        },
        setSortingColumnName(state, action: PayloadAction<string>) {
            state.sorting = {
                ...state.sorting,
                column: action.payload,
            };
        },
        setSortingDirection(state, action: PayloadAction<'asc' | 'desc'>) {
            state.sorting = {
                ...state.sorting,
                direction: action.payload,
            };
        },
        clearSorting(state) {
            state.pagination = initialState.pagination;
            state.sorting = initialState.sorting;
        },
        clearPaginationForTabChange(state) {
            state.pagination = {
                ...initialState.pagination,
                pageSize: state.pagination.pageSize,
            };
        },
        deleteOrder(state, action: PayloadAction<string>) {
            const orders = [...(state?.dataList || [])] as Order[];

            remove(
                orders,
                (item: Order) => 'id' in item && item.id === action.payload,
            );

            state.dataList = orders;
            state.pagination.total = state.pagination.total - 1;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrders.pending, (state) => {
                state.error = null;
                state.listIsLoading = true;
            })
            .addCase(fetchOrders.fulfilled, (state, action) => {
                const response = action.payload;

                state.dataList = response.results;
                state.pagination.total = action.payload.count;
                state.listIsLoading = false;
            })
            .addCase(fetchOrders.rejected, (state, action) => {
                state.dataList = [];
                state.listIsLoading = false;
                state.error = action.payload;
            })
            .addCase(fetchOrdersCounters.pending, (state) => {
                state.error = null;
                state.countersAreLoading = true;
            })
            .addCase(fetchOrdersCounters.fulfilled, (state, action) => {
                state.counters = action.payload;
                state.countersAreLoading = false;
            })
            .addCase(fetchOrdersCounters.rejected, (state, action) => {
                state.countersAreLoading = false;
                state.counters = null;
                state.error = action.payload;
            });
    },
});

export const { actions: orderTableActions } = OrderTableSlice;
export const { reducer: orderTableReducer } = OrderTableSlice;
