import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderStatuses } from 'entities/Order/model/consts/orderStatuses';

import { ChangeOrderStatusButton } from './ChangeOrderStatusButton';

interface PlaceOrderButtonProps {
    orderId: string;
    isNoItemsInOrder: boolean;
}

export const MoveToPendingOrderButton = memo(
    ({ orderId, isNoItemsInOrder }: PlaceOrderButtonProps) => {
        const { t } = useTranslation();

        return (
            <ChangeOrderStatusButton
                newStatus={OrderStatuses.PENDING_APPROVAL}
                orderId={orderId}
                messageOnSuccess={t('Order moved to Manager Review')}
                variant="contained"
                disabled={isNoItemsInOrder}
            >
                {t('Send for review')}
            </ChangeOrderStatusButton>
        );
    },
);
