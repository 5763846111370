import { Box, Button, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getSelectedCatalogItem } from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { catalogItemActions } from 'entities/CatalogItem/model/slices/CatalogItemSlice';
import { CatalogItem } from 'entities/CatalogItem/model/types/CatalogItemSchema';
import { getDisableChangeSupplierAndDeleteItems } from 'entities/Order/model/selectors/ordersPermissions';
import {
    getSelectedOrderId,
    getSelectedOrderItems,
} from 'entities/Order/model/selectors/ordersSelectors';
import { Proposal } from 'entities/Proposal/model/types/ProposalSchema';
import { RemoveItemFromOrder } from 'features/RemoveItemFromOrder';
import {
    UpdateOrderItemProposalButton,
    getDataForSelectedProposal,
    getSelectedOrderItemProposalId,
} from 'features/UpdateOrderItemProposal';
import { updateOrderItemProposalActions } from 'features/UpdateOrderItemProposal/model/slices/UpdateOrderItemProposalSlice';
import { getRouteOrdersView } from 'shared/const/router';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { useUnmountEffect } from 'shared/lib/hooks/useUnmountEffect';
import { BackButton } from 'shared/ui/BackButton';

import { OrderViewItemDetailedInfo } from './OrderViewItemDetailedInfo';
import { OrderViewItemDetailedSuppliersRadio } from './OrderViewItemDetailedSupliersRadio';

export const OrderViewItemDetailed = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const selectedItem = useSelector(getSelectedCatalogItem) as CatalogItem;
    const orderId = useSelector(getSelectedOrderId);
    const selectedItems = useSelector(getSelectedOrderItems);
    const selectedProposalId = useSelector(getSelectedOrderItemProposalId);
    const disableChangeSupplierAndDeleteItems = useSelector(
        getDisableChangeSupplierAndDeleteItems,
    );

    const itemId = selectedItem?.id;

    const selectedItemData = useMemo(
        () => selectedItems?.find((item) => item.id === itemId),
        [selectedItems, itemId],
    );

    const [
        proposals,
        isHighlightedPreferredMode,
        highlightedPreferredProposalsTotal,
        otherProposalsTotal,
        otherProposals,
    ] = useMemo(() => {
        let highlightedPreferredProposalsTotal = 0;
        let otherProposalsTotal = 0;
        let highlightedPreferredProposals: Proposal[] = [];
        let otherProposals: Proposal[] = [];
        let proposals = selectedItemData?.proposals || [];

        proposals?.forEach((item) => {
            if (item?.productSourcingMode === 'HIGHLIGHT_PREFERRED') {
                highlightedPreferredProposals.push({
                    ...item,
                    isPreferred: true,
                });
                highlightedPreferredProposalsTotal++;
            } else {
                otherProposals.push(item);
                otherProposalsTotal++;
            }
        });

        const isHighlightedPreferredMode =
            highlightedPreferredProposalsTotal > 0;

        const isOnlyPreferredMode = proposals.find(
            (proposal) => proposal.productSourcingMode === 'ONLY_PREFERRED',
        );

        if (isOnlyPreferredMode) {
            proposals = proposals?.map((item: Proposal) => {
                if (item?.productSourcingMode === 'ONLY_PREFERRED') {
                    return { ...item, isPreferred: true };
                } else {
                    return item;
                }
            });
        }
        return [
            isHighlightedPreferredMode
                ? highlightedPreferredProposals
                : proposals,
            isHighlightedPreferredMode,
            highlightedPreferredProposalsTotal,
            otherProposalsTotal,
            otherProposals,
        ];
    }, [selectedItemData?.proposals]);

    const selectedProposalFromItem = selectedItemData?.selectedProposal;
    const proposalsTotal = selectedItemData?.proposals?.length;

    const onBack = useCallback(() => {
        navigate(getRouteOrdersView(orderId));
    }, [navigate, orderId]);

    const onSupplierChange = (proposal: Proposal) => {
        dispatch(
            updateOrderItemProposalActions.setProposal(
                getDataForSelectedProposal(proposal),
            ),
        );
    };

    useEffect(() => {
        const proposalInItem = selectedProposalFromItem?.uid
            ? selectedProposalFromItem
            : null;

        const proposalToSelect =
            proposalInItem || getDataForSelectedProposal(proposals?.[0]);

        if (proposalToSelect) {
            dispatch(
                updateOrderItemProposalActions.setProposal(proposalToSelect),
            );
        }
    }, [dispatch, proposals, selectedProposalFromItem]);

    useUnmountEffect(() => {
        dispatch(catalogItemActions.setSelectedItem(null));
    });

    return (
        <Box>
            <Box
                p="16px 16px 0px"
                height={'calc(100vh - 204px)'}
                sx={{ overflowY: 'scroll' }}
            >
                <Box display="flex" flexDirection="column" gap="8px">
                    <Box>
                        <BackButton
                            onBack={onBack}
                            title={t('Back to order review')}
                            marginBottom={'26px'}
                        />
                    </Box>
                    <Box display="flex">
                        <Typography mb={'16px'} typography="poppins.h5">
                            {t('Item')}
                        </Typography>
                        {!disableChangeSupplierAndDeleteItems && (
                            <RemoveItemFromOrder itemId={itemId} />
                        )}
                    </Box>
                    <Box mb={'16px'}>
                        <OrderViewItemDetailedInfo />
                    </Box>
                    <Box>
                        <Typography mb={'16px'} typography="poppins.h5">
                            {t('Select Supplier')}
                        </Typography>
                        <Typography
                            mb={'16px'}
                            typography="openSans.subtitle1Medium"
                        >
                            {t('Offers from Suppliers ')} ({proposalsTotal}):
                        </Typography>
                        {isHighlightedPreferredMode && (
                            <Typography
                                mb={'8px'}
                                typography="openSans.body2"
                                color="#828282"
                            >
                                {t('Preferred Suppliers')} (
                                {highlightedPreferredProposalsTotal}
                                ):
                            </Typography>
                        )}
                        <OrderViewItemDetailedSuppliersRadio
                            proposals={proposals}
                            onChange={onSupplierChange}
                            selectedProposalId={selectedProposalId}
                            disabled={disableChangeSupplierAndDeleteItems}
                        />
                        {isHighlightedPreferredMode && (
                            <>
                                <Typography
                                    mt={'16px'}
                                    mb={'8px'}
                                    typography="openSans.body2"
                                    color="#828282"
                                >
                                    {t('Other Suppliers')} (
                                    {otherProposalsTotal}
                                    ):
                                </Typography>
                                {otherProposalsTotal > 0 ? (
                                    <OrderViewItemDetailedSuppliersRadio
                                        proposals={otherProposals}
                                        onChange={onSupplierChange}
                                        selectedProposalId={selectedProposalId}
                                        disabled={
                                            disableChangeSupplierAndDeleteItems
                                        }
                                    />
                                ) : (
                                    <Typography
                                        mt={'12px'}
                                        mb={'8px'}
                                        typography="openSans.subtitle1Medium"
                                        color="#828282"
                                    >
                                        {t(
                                            'There are no other suppliers available',
                                        )}
                                    </Typography>
                                )}
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
            <Box
                display="flex"
                justifyContent="flex-end"
                p="12px 16px 16px"
                bgcolor="background.paper"
                borderTop="1px solid"
                borderColor="divider"
            >
                <Box display="flex" gap="16px">
                    <Button onClick={onBack}>{t('Cancel')}</Button>
                </Box>
                {!disableChangeSupplierAndDeleteItems && (
                    <UpdateOrderItemProposalButton
                        itemIdToEdit={itemId}
                        selectedOrderId={orderId}
                    />
                )}
            </Box>
        </Box>
    );
};
