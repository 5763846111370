import { createSelector } from 'reselect';

import { StateSchema } from 'app/providers/StoreProvider';

export const getNotifications = createSelector(
    (state: StateSchema) => state.notification,
    (notification) => notification.dataList || [],
);

export const getHasUnreadNotifications = createSelector(
    (state: StateSchema) => state.notification,
    (notification) => Boolean(notification?.unreadCounter),
);

export const getNotificationsUnreadIds = createSelector(
    (state: StateSchema) => state.notification,
    (notification) => notification?.unreadIds || [],
);

export const getUnreadNotificationsCounter = createSelector(
    (state: StateSchema) => state.notification,
    (notification) => notification?.unreadCounter,
);

export const getNotificationsAreLoading = (state: StateSchema) =>
    state.notification.initialIsLoading;

export const getNotificationsAreLoadingMore = (state: StateSchema) =>
    state.notification.moreIsLoading;

export const getNotificationsHasMore = (state: StateSchema) =>
    state.notification?.pagination?.hasMore;

export const getNotificationsCurrentPage = (state: StateSchema) =>
    state?.notification?.pagination?.currentPage;

export const getNotificationsPageSize = (state: StateSchema) =>
    state.notification?.pagination?.pageSize;
