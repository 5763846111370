import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkConfig } from 'app/providers/StoreProvider';
import { catalogItemActions } from 'entities/CatalogItem/model/slices/CatalogItemSlice';

import {
    AddInventoryItemRequestBody,
    AddInventoryItemResponse,
} from '../types/AddInventoryItemSchema';

export const addInventoryItemService = createAsyncThunk<
    AddInventoryItemResponse,
    AddInventoryItemRequestBody,
    ThunkConfig<any>
>('catalogItem/addToInventory', async ({ data, catalogId }, thunkApi) => {
    const { extra, rejectWithValue, dispatch } = thunkApi;

    try {
        const response = await extra.api.post<AddInventoryItemResponse>(
            `/v1/catalogs/${catalogId}/items`,
            data,
        );

        dispatch(catalogItemActions.removeAllSelection());

        return response.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
