import { Box, List, ListItem, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { AppLink } from 'shared/ui/AppLink/AppLink';

import { PageNavItem } from '../model/types/PageNavBarTypes';

interface PageNavbarProps {
    navList: PageNavItem[];
    title: string;
}

export const PageNavbar = ({ navList, title }: PageNavbarProps) => {
    const location = useLocation();
    const hasItems = navList?.length > 0;

    return (
        hasItems && (
            <>
                <Box
                    data-testid="Sub-menu"
                    bgcolor="background.paper"
                    minWidth="200px"
                    maxWidth="260px"
                    sx={{ overflowY: 'auto' }}
                    pt={title ? '18px' : '0'}
                >
                    {title && (
                        <Typography
                            data-testid="Sub-menu-title"
                            ml="16px"
                            mb="18px"
                            typography={'poppins.subtitle1'}
                        >
                            {title}
                        </Typography>
                    )}
                    <List data-testid="properties-list">
                        {navList.map((item) => {
                            const { label } = item;
                            const key = item?.key;
                            const route = item?.route;
                            const isActiveNav = item?.isActive;
                            const action = item.disabled
                                ? () => {}
                                : item?.action;

                            const isActive = route
                                ? location.pathname === item.route
                                : isActiveNav;

                            const componentProps = route
                                ? {
                                      component: AppLink,
                                      to: route,
                                      replace: true,
                                  }
                                : {
                                      onClick: () => action(),
                                  };

                            return (
                                <ListItem
                                    data-testid="Sub-menu-property"
                                    key={key}
                                    sx={{
                                        p: 0,
                                        ':hover': {
                                            bgcolor: 'action.hover',
                                            cursor: 'pointer',
                                        },
                                        bgcolor: isActive && 'primary.100',
                                    }}
                                >
                                    <Box
                                        {...componentProps}
                                        width="100%"
                                        display="flex"
                                        alignItems="center"
                                        p="8px 16px"
                                        borderLeft="2px solid"
                                        borderColor={
                                            isActive
                                                ? 'primary.main'
                                                : 'transparent'
                                        }
                                    >
                                        <Typography
                                            whiteSpace="nowrap"
                                            overflow="hidden"
                                            textOverflow="ellipsis"
                                            component="span"
                                            display="inline-block"
                                            flex="1"
                                            fontFamily="Poppins"
                                            fontSize="16px"
                                            fontWeight={isActive ? 600 : 400}
                                            sx={{
                                                opacity: !isActive
                                                    ? '0.6'
                                                    : undefined,
                                            }}
                                            color={
                                                isActive
                                                    ? 'primary.main'
                                                    : 'text.disabled'
                                            }
                                        >
                                            {label}
                                        </Typography>
                                    </Box>
                                </ListItem>
                            );
                        })}
                    </List>
                </Box>
                <Box minWidth="200px" maxWidth="260px" />
            </>
        )
    );
};
