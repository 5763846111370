import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkConfig } from 'app/providers/StoreProvider';
import { Category } from 'entities/Category';

interface ChangeCategoryServiceParams {
    categoryId: string;
    catalogId: string;
    color: string;
    glCode: string;
    title?: string;
}

export const changeCategoryService = createAsyncThunk<
    Category,
    ChangeCategoryServiceParams,
    ThunkConfig<string>
>(
    'category/changeCategoryService',
    async ({ catalogId, categoryId, color, title, glCode }, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.patch<Category>(
                `/v1/catalogs/${catalogId}/categories/${categoryId}`,
                {
                    color,
                    glCode,
                    title,
                },
            );
            return response.data;
        } catch (e) {
            const errorData = e?.response?.data;

            if (errorData) {
                return rejectWithValue(errorData);
            }

            return rejectWithValue('error');
        }
    },
);
