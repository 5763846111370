import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkConfig } from 'app/providers/StoreProvider';

import { SupplierPropertiesResponseSchema } from '../types/SupplierSchema';

export const fetchSupplierProperties = createAsyncThunk<
    SupplierPropertiesResponseSchema,
    { supplierId: string; propertyId: string },
    ThunkConfig<string>
>('supplier/properties', async ({ supplierId, propertyId }, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.get<SupplierPropertiesResponseSchema>(
            `/v1/properties/${propertyId}/vendors/${supplierId}/properties`,
        );

        return response.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
