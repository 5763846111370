import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkConfig } from 'app/providers/StoreProvider';
import { Property } from 'entities/Property/model/types/PropertySchema';

export const fetchPropertyById = createAsyncThunk<
    Property,
    string,
    ThunkConfig<string>
>('property/fetchPropertyById', async (id, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.get<Property>(`/v1/properties/${id}`);

        return response.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
