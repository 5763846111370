import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkConfig } from 'app/providers/StoreProvider';

import { Supplier } from '../../types/ProductSourcingSchema';

export const fetchSuppliers = createAsyncThunk<
    { data: Supplier[] },
    string,
    ThunkConfig<string>
>('fetchSuppliers', async (propertyId, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.get<{ data: Supplier[] }>(
            `/v1/product_sourcing/${propertyId}/suppliers`,
        );

        return response.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
