import { Box, Button, Dialog, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
    getSelectedCatalogItem,
    getSelectedCatalogItems,
} from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { getSelectedPropertyCatalogId } from 'entities/Property/model/selectors/PropertySelectors';
import { reorderInventoryItemActions } from 'features/ReorderInventoryItem/model/slices/ReorderInventoryItemSlice';
import {
    getMoveToInventoryIsSingle,
    getMoveToInventoryItemDialog,
    geyMoveToInventoryItemIsLoading,
} from 'features/moveToInventoryCatalogItem/model/selectors/moveToInventoryItemSelectors';
import { moveToInventoryItemService } from 'features/moveToInventoryCatalogItem/model/services/moveToInventoryItemService';
import { moveToInventoryItemActions } from 'features/moveToInventoryCatalogItem/model/slices/MoveToInventoryItemSlice';
import { getRoutePropertiesCatalogInventory } from 'shared/const/router';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { DialogBoxContent } from 'shared/ui/DialogBoxContent/DialogBoxContent';
import { Loader } from 'shared/ui/Loader/Loader';

export const MoveToInventoryDialog = ({
    onSubmit,
    onClose,
    isHideReorderButton,
}: {
    onSubmit?: () => void;
    onClose?: () => void;
    isHideReorderButton?: boolean;
}) => {
    const [isClickMoveWithReorder, setIsClickMoveWithReorder] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useAppDispatch();
    const isOpened = useSelector(getMoveToInventoryItemDialog);
    const isLoading = useSelector(geyMoveToInventoryItemIsLoading);
    const selectedItems = useSelector(getSelectedCatalogItems);
    const selectedItem = useSelector(getSelectedCatalogItem);
    const selectedCatalogId = useSelector(getSelectedPropertyCatalogId);
    const isSingleMove = useSelector(getMoveToInventoryIsSingle);

    const close = () => {
        dispatch(moveToInventoryItemActions.setShowDialog(false));

        if (onClose) {
            onClose();
        }
    };

    const submit = async (isEditFlow: boolean, isClickWithReorder: boolean) => {
        setIsClickMoveWithReorder(isClickWithReorder);
        const idOfSelectedItem = (selectedItem as any)?.id;

        const data = {
            items:
                isSingleMove && idOfSelectedItem
                    ? [
                          {
                              id: idOfSelectedItem,
                              isInventory: true,
                          },
                      ]
                    : Object.keys(selectedItems).map((id) => ({
                          id,
                          isInventory: true,
                      })),
        };

        await dispatch(
            moveToInventoryItemService({
                catalogId: selectedCatalogId,
                data,
                isSingle: isSingleMove,
            }),
        );

        if (isEditFlow) {
            dispatch(reorderInventoryItemActions.setEditFlow(true));
            dispatch(
                reorderInventoryItemActions.setEditFlowFromCategories(true),
            );
            navigate(getRoutePropertiesCatalogInventory(params?.propertyId));
        }

        if (onSubmit) {
            onSubmit();
        }
    };

    return (
        isOpened && (
            <Dialog open onClose={close}>
                <DialogBoxContent
                    onClose={close}
                    buttons={
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            width="100%"
                            gap="10px"
                        >
                            <Button onClick={close}>{t('Cancel')}</Button>
                            {!isHideReorderButton && (
                                <Button
                                    variant="outlined"
                                    sx={{ textTransform: 'none' }}
                                    disabled={isLoading}
                                    startIcon={
                                        isLoading &&
                                        isClickMoveWithReorder && (
                                            <Loader color="inherit" />
                                        )
                                    }
                                    data-testid="InventroyCatalogPage.AddAndEditFlowBtn"
                                    onClick={() => submit(true, true)}
                                >
                                    {t('Add and edit flow')}
                                </Button>
                            )}
                            <Button
                                disabled={isLoading}
                                startIcon={
                                    isLoading &&
                                    !isClickMoveWithReorder && (
                                        <Loader color="inherit" />
                                    )
                                }
                                onClick={() => submit(false, false)}
                                variant="contained"
                                color="primary"
                            >
                                {t('Add to inventory')}
                            </Button>
                        </Box>
                    }
                >
                    <Box p="0 24px">
                        <Typography typography={'poppins.subtitle2'} mb="18px">
                            {isSingleMove
                                ? t('Add Item to Inventory')
                                : t('Add Items to Inventory')}
                        </Typography>
                        <Typography
                            typography={'openSans.body2'}
                            mb="18px"
                            maxWidth="350px"
                        >
                            {isSingleMove
                                ? t(
                                      'Please confirm that you would like to add the selected item to Inventory',
                                  )
                                : t(
                                      'Please confirm that you would like to add the selected items to Inventory',
                                  )}
                            .
                        </Typography>
                    </Box>
                </DialogBoxContent>
            </Dialog>
        )
    );
};
