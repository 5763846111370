import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SearchCatalogItemsSchema } from '../types/SearchCatalogItemsSchema';

const initialState: SearchCatalogItemsSchema = {
    isActive: false,
    globalCatItemSearchValue: '',
};

export const SearchCatalogItemsSlice = createSlice({
    name: 'SearchCatalogItems',
    initialState,
    reducers: {
        setActive: (state, action: PayloadAction<boolean>) => {
            state.isActive = action.payload;
        },
        setGlobalCatItemSearchValue: (state, action: PayloadAction<string>) => {
            state.globalCatItemSearchValue = action.payload;
        },
    },
});

export const { actions: searchCatalogItemsActions } = SearchCatalogItemsSlice;
export const { reducer: searchCatalogItemsReducer } = SearchCatalogItemsSlice;
