import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { assignVendorToProperty } from '../services/assignVendorToProperty';
import { fetchAllSpecialties } from '../services/fetchAllSpecialties';
import { fetchAllVendorsList } from '../services/fetchAllVendorsList';
import { fetchPropertyVendorsList } from '../services/fetchPropertyVendorsList';
import { updateVendorInfo } from '../services/updateVendorInfo';
import { Vendor, VendorsSchema } from '../types/VendorsSchema';

const initialState: VendorsSchema = {
    dataIsLoading: false,
    vendorsList: [],
    allVendorsList: [],
    allSpecialties: [],
    currentVendorToAdd: null,
    isShowVendorToAddDialog: false,
};

export const VendorsSlice = createSlice({
    name: 'Vendors',
    initialState,
    reducers: {
        setVendorToAddProperty: (state, action: PayloadAction<Vendor>) => {
            state.currentVendorToAdd = action.payload;
        },
        setIsShowVendorAddDialog: (state, action: PayloadAction<boolean>) => {
            state.isShowVendorToAddDialog = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPropertyVendorsList.pending, (state) => {
                state.dataIsLoading = true;
            })
            .addCase(fetchPropertyVendorsList.fulfilled, (state, action) => {
                const listOfVendors = action.payload.data;
                const sortingVendorsListByAsc = listOfVendors.sort((a, b) =>
                    a?.title.localeCompare(b?.title),
                );
                state.vendorsList = sortingVendorsListByAsc;
                state.dataIsLoading = false;
            })
            .addCase(fetchPropertyVendorsList.rejected, (state, action) => {
                state.dataIsLoading = false;
            })
            .addCase(fetchAllVendorsList.pending, (state) => {
                state.dataIsLoading = true;
            })
            .addCase(fetchAllVendorsList.fulfilled, (state, action) => {
                const listOfVendors = action.payload.data;
                const sortingAllVendorsListByAsc = listOfVendors.sort((a, b) =>
                    a?.title.localeCompare(b?.title),
                );
                state.allVendorsList = sortingAllVendorsListByAsc;
                state.dataIsLoading = false;
            })
            .addCase(fetchAllVendorsList.rejected, (state, action) => {
                state.dataIsLoading = false;
            })
            .addCase(assignVendorToProperty.pending, (state) => {
                state.dataIsLoading = true;
            })
            .addCase(assignVendorToProperty.fulfilled, (state, action) => {
                state.dataIsLoading = false;
            })
            .addCase(assignVendorToProperty.rejected, (state, action) => {
                state.dataIsLoading = false;
            })
            .addCase(updateVendorInfo.pending, (state) => {
                state.dataIsLoading = true;
            })
            .addCase(updateVendorInfo.fulfilled, (state, action) => {
                state.dataIsLoading = false;
            })
            .addCase(updateVendorInfo.rejected, (state, action) => {
                state.dataIsLoading = false;
            })
            .addCase(fetchAllSpecialties.pending, (state) => {
                state.dataIsLoading = true;
            })
            .addCase(fetchAllSpecialties.fulfilled, (state, action) => {
                const listOfSpecialties = action.payload.data;
                state.allSpecialties = listOfSpecialties;
                state.dataIsLoading = false;
            })
            .addCase(fetchAllSpecialties.rejected, (state, action) => {
                state.dataIsLoading = false;
            });
    },
});

export const { actions: vendorsActions } = VendorsSlice;
export const { reducer: vendorsReducer } = VendorsSlice;
