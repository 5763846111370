import camelCase from 'lodash/camelCase';
import capitalize from 'lodash/capitalize';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import has from 'lodash/has';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import kebabCase from 'lodash/kebabCase';
import remove from 'lodash/remove';
import set from 'lodash/set';
import snakeCase from 'lodash/snakeCase';
import startCase from 'lodash/startCase';
import toNumber from 'lodash/toNumber';
import unset from 'lodash/unset';

function isEmail(value: string) {
    const re = /\S+@\S+\.\S+/;
    return re.test(value);
}

export {
    camelCase,
    capitalize,
    debounce,
    find,
    groupBy,
    has,
    isArray,
    isEmail,
    isEmpty,
    isObject,
    kebabCase,
    remove,
    set,
    snakeCase,
    startCase,
    toNumber,
    unset,
};
