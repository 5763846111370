import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkConfig } from 'app/providers/StoreProvider';

import { Order } from '../../types/OrderSchema';

export const createOrder = createAsyncThunk<
    Order,
    { propertyId: string; orderType: string },
    ThunkConfig<string>
>('order/createOrder', async ({ propertyId, orderType }, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
        const response = await extra.api.post<Order>(`/v1/orders/`, {
            propertyId,
            orderType,
        });

        return response.data;
    } catch (e) {
        return rejectWithValue('error');
    }
});
