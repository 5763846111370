import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkConfig } from 'app/providers/StoreProvider';

import { GlobalCategory } from '../../types/CategorySchema';

export interface GlobalCategoriesRequestParams {
    data: {
        searchStr?: string;
        parentId?: string;
    };
    abortController?: AbortController;
}

export const fetchGlobalCategories = createAsyncThunk<
    GlobalCategory[],
    GlobalCategoriesRequestParams,
    ThunkConfig<string>
>(
    'globalCategory/fetchGlobalCategories',
    async ({ data: { searchStr, parentId }, abortController }, thunkApi) => {
        const { extra, rejectWithValue } = thunkApi;

        try {
            const response = await extra.api.get<GlobalCategory[]>(
                `/v1/catalogs/global-categories`,
                {
                    params: {
                        searchStr,
                        parentId,
                    },
                    signal: abortController?.signal,
                },
            );

            return response.data;
        } catch (e) {
            return rejectWithValue('error');
        }
    },
);
