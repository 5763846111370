import { Box } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Notification } from 'entities/Notification';
import { getDataFromNotification } from 'entities/Notification/lib/getDataFromNotification';
import {
    getNotifications,
    getNotificationsAreLoading,
    getNotificationsAreLoadingMore,
    getNotificationsHasMore,
} from 'entities/Notification/model/selectors/notificationSelectors';
import { fetchMoreNotifications } from 'entities/Notification/model/services/fetchMoreNotifications/fetchMoreNotifications';
import { NotificationActions } from 'entities/Notification/model/slices/NotificationsSlice';
import { Notification as NotificationType } from 'entities/Notification/model/types/NotificationsSchema';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import LazyLoadOnScroll from 'shared/ui/LazyLoadOnScroll/LazyLoadOnScroll';
import { Loader } from 'shared/ui/Loader/Loader';

interface NotificationsListProps {
    onClose: () => void;
}

export const NotificationsList = ({ onClose }: NotificationsListProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const hasMore = useSelector(getNotificationsHasMore);
    const isLoading = useSelector(getNotificationsAreLoading);
    const isLoadingMore = useSelector(getNotificationsAreLoadingMore);

    const data = useSelector(getNotifications);
    const hasData = Boolean(data.length);

    const markReadByIndex = useCallback(
        (index: number) => () => {
            dispatch(NotificationActions.makeReadByIndex(index));
        },
        [dispatch],
    );

    const loadMore = useCallback(async () => {
        if (hasMore && !isLoading && !isLoadingMore) {
            dispatch(fetchMoreNotifications());
        }
    }, [dispatch, hasMore, isLoading, isLoadingMore]);

    const renderItem = (notification: NotificationType, index: number) => {
        const notificationData = getDataFromNotification(notification);
        return (
            <Notification
                key={notification.id}
                {...notificationData}
                showBorder={index !== data.length - 1}
                closePopup={onClose}
                markReadByIndex={markReadByIndex(index)}
            />
        );
    };

    return hasData ? (
        <Box sx={{ overflowY: 'scroll' }} data-testid="NotificationsList">
            <LazyLoadOnScroll
                items={data}
                renderItem={renderItem}
                onLoad={loadMore}
            />
            {isLoadingMore && (
                <Box display="flex" justifyContent="center" p="16px">
                    <Loader />
                </Box>
            )}
        </Box>
    ) : (
        <Box data-testid="NotificationsList.Empty" p="16px">
            {t('There are no notifications')}
        </Box>
    );
};
