import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Box, Link, List, ListItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const listItemStyles = {
    sx: {
        p: 0,
        ':hover': {
            bgcolor: 'action.hover',
            cursor: 'pointer',
        },
    },
};

const listItemContentStyles = {
    width: '100%',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    p: '0 16px',
    color: 'action.active',
};

export const HelpMenuItems = () => {
    const { t } = useTranslation();

    return (
        <List
            sx={{
                padding: 0,
            }}
        >
            <ListItem {...listItemStyles}>
                <Box
                    component={Link}
                    href={'mailto:help@re-ops.co'}
                    sx={{ textDecoration: 'none' }}
                    {...listItemContentStyles}
                >
                    <EmailOutlinedIcon sx={{ color: 'action.active ' }} />
                    <Typography
                        component="span"
                        display="inline-block"
                        flex="1"
                        fontFamily="Open Sans"
                        fontSize="16px"
                        ml="12px"
                    >
                        {t('Email Us')}
                    </Typography>
                </Box>
            </ListItem>

            <ListItem {...listItemStyles}>
                <Box
                    component={Link}
                    href={'https://help.re-ops.io/'}
                    target={'_blank'}
                    sx={{ textDecoration: 'none' }}
                    {...listItemContentStyles}
                >
                    <HelpOutlineOutlinedIcon sx={{ color: 'action.active ' }} />
                    <Typography flex="1" fontSize="16px" ml="12px">
                        {t('Knowledge Base')}
                    </Typography>
                </Box>
            </ListItem>
        </List>
    );
};
