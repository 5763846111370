import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import {
    getInteractionStatus,
    getInvoiceStatus,
    getShipmentStatus,
} from 'entities/Order/model/selectors/ordersSelectors';

import { OrderInvoiceDetails } from './OrderInvoiceDetails';
import { OrderShipmentDetails } from './OrderShipmentDetails';
import { OrderStatusDetails } from './OrderStatusDetails';
import { OrderViewPageDetails } from './OrderViewPageDetails';
import { OrderViewSummaryDetails } from './OrderViewSummaryDetails';

interface OrderViewPageInfoProps {
    summaryByCategories: Record<
        string,
        {
            count: number;
            cost: number;
        }
    >;
    isLoading: boolean;
}

export const OrderViewPageInfo = ({
    summaryByCategories,
    isLoading,
}: OrderViewPageInfoProps) => {
    const interactionStatus = useSelector(getInteractionStatus);
    const shipmentStatus = useSelector(getShipmentStatus);
    const invoiceStatus = useSelector(getInvoiceStatus);

    return (
        <Box sx={{ mb: '16px' }}>
            {!isLoading && (
                <>
                    <OrderViewPageDetails />
                    <OrderViewSummaryDetails
                        isLoadingSummaryDetails={isLoading}
                        summaryByCategories={summaryByCategories}
                        isInteractionStatus={!!interactionStatus}
                    />
                    {interactionStatus && (
                        <OrderStatusDetails
                            interactionStatus={interactionStatus}
                        />
                    )}
                    {shipmentStatus && (
                        <OrderShipmentDetails shipmentStatus={shipmentStatus} />
                    )}
                    {invoiceStatus && (
                        <OrderInvoiceDetails invoiceStatus={invoiceStatus} />
                    )}
                </>
            )}
        </Box>
    );
};
