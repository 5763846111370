import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import axios from 'axios';
import { toast } from 'react-toastify';

import { StateSchema } from 'app/providers/StoreProvider';
import { userActions } from 'entities/User';
import { isJestRuntime } from 'shared/const/envVariables';
import { USER_ACCESS_TOKEN } from 'shared/const/localstorage';
import { convertKeysToCamelCase } from 'shared/lib/convertKeysToCamelCase/convertKeysToCamelCase';
import { convertKeysToSnakeCase } from 'shared/lib/convertKeysToSnakeCase/convertKeysToSnakeCase';

let store: ToolkitStore<StateSchema>;

export const injectStore = (_store: ToolkitStore<StateSchema>) => {
    store = _store;
};

const apiUrl =
    process.env.REACT_APP_REOPS_API ||
    `${window.location.protocol}//api.${window.location.hostname}`;

export const $api = axios.create({
    baseURL: apiUrl,
});

$api.interceptors.request.use((config) => {
    const newConfig = { ...config };
    newConfig.url = config.url;

    if (newConfig.headers) {
        newConfig.headers.Authorization = `Bearer ${
            localStorage.getItem(USER_ACCESS_TOKEN) || ''
        }`;
    }

    if (config.data && !(config.data instanceof FormData)) {
        newConfig.data = convertKeysToSnakeCase(config.data);
    }

    if (config.params) {
        newConfig.params = convertKeysToSnakeCase(config.params);
    }

    return newConfig;
});

$api.interceptors.response.use(
    (response) => {
        if (response.data && !(response.data instanceof Blob)) {
            response.data = convertKeysToCamelCase(response.data);
        }

        return response;
    },
    (error) => {
        if (error?.response?.status === 401) {
            if (!isJestRuntime) {
                store.dispatch(userActions.logout());
            }

            toast('Session expired');
        }
        return Promise.reject(error);
    },
);
