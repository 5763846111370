import { Button } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getSelectedCatalogItemsTotal } from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { getUserCanEditPropertyCatalog } from 'entities/User/model/selectors/userPermissionsSeletors';
import { addInventoryItemActions } from 'features/AddInventoryItem/model/slices/AddInventoryItemSlice';
import { reorderInventoryItemActions } from 'features/ReorderInventoryItem/model/slices/ReorderInventoryItemSlice';
import { WithMoveToInventoryCatalogItem } from 'features/moveToInventoryCatalogItem';
import { MoveToInventoryDialog } from 'features/moveToInventoryCatalogItem/ui/MoveToInventoryDialog/MoveToInventoryDialog';
import {
    getRouteForbidden,
    getRoutePropertiesCatalogInventory,
} from 'shared/const/router';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { useInitialEffect } from 'shared/lib/hooks/useInitialEffect';
import {
    PropertyCatalog,
    PropertyCatalogItemsMoveTo,
} from 'widgets/PropertyCatalog';

export default () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const selectedItemsTotal = useSelector(getSelectedCatalogItemsTotal);
    const canEditCatalog = useSelector(getUserCanEditPropertyCatalog);

    const [isEditModeAdding, setIsEditModeAdding] = useState(false);

    const submitButtonIsActive = selectedItemsTotal > 0;

    const onCancel = useCallback(() => {
        setIsEditModeAdding(false);
        dispatch(addInventoryItemActions.setEditItemMode(false));
        navigate(getRoutePropertiesCatalogInventory(params?.propertyId));
    }, [dispatch, navigate, params?.propertyId]);

    const onLeave = useCallback(() => {
        dispatch(addInventoryItemActions.setEditItemMode(false));
    }, [dispatch]);

    const onModalClose = () => {
        setIsEditModeAdding(false);
    };

    const onModalSubmit = useCallback(() => {
        if (isEditModeAdding) {
            dispatch(reorderInventoryItemActions.setEditFlow(true));
        }
        onCancel();
    }, [dispatch, isEditModeAdding, onCancel]);

    useInitialEffect(() => {
        if (!canEditCatalog) {
            navigate(getRouteForbidden());
        }

        dispatch(addInventoryItemActions.setEditItemMode(true));
    });

    return (
        <>
            <PropertyCatalog
                isEditing
                onLeave={onLeave}
                moveTo={PropertyCatalogItemsMoveTo.INVENTORY}
                actionButtons={
                    <>
                        <WithMoveToInventoryCatalogItem
                            isDisabled={!submitButtonIsActive}
                        >
                            <Button
                                variant="outlined"
                                disabled={!submitButtonIsActive}
                                data-testid="InventroyCatalogPage.AddAndEditFlowBtn"
                                onClick={() => setIsEditModeAdding(true)}
                            >
                                {t('Add and edit flow')}
                            </Button>
                        </WithMoveToInventoryCatalogItem>
                        <WithMoveToInventoryCatalogItem
                            isDisabled={!submitButtonIsActive}
                        >
                            <Button
                                data-testid="InventroyCatalogPage.AddBtn"
                                variant="contained"
                                disabled={!submitButtonIsActive}
                            >
                                {t('Add')}
                            </Button>
                        </WithMoveToInventoryCatalogItem>
                    </>
                }
            />
            <MoveToInventoryDialog
                onSubmit={onModalSubmit}
                onClose={onModalClose}
                isHideReorderButton={true}
            />
        </>
    );
};
