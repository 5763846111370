import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getSelectedPropertyCatalogId } from 'entities/Property/model/selectors/PropertySelectors';
import { CreateOrderWithItemsButton } from 'features/AddItemsToOrder';
import { addItemsToOrderIsLoading } from 'features/AddItemsToOrder/model/selectors/addItemsToOrderSelectors';
import { getSearchCatalogItemIsActive } from 'features/SearchCatalogItems/model/selectors/searchCatalogItemsSelectors';
import {
    PropertyCatalog,
    PropertyCatalogItemsMoveTo,
} from 'widgets/PropertyCatalog';
import { SearchCatalogItems } from 'widgets/SearchCatalogItems';

export const OrderAddItemPageCatalog = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const isLoadingUpdateOrder = useSelector(addItemsToOrderIsLoading);
    const isActive = useSelector(getSearchCatalogItemIsActive);
    const selectedPropertyCatalogId = useSelector(getSelectedPropertyCatalogId);

    const catalogId =
        location.state?.selectedPropertyCatalogId || selectedPropertyCatalogId;

    const orderType = location.state?.orderType;

    return (
        <Box flex={1} display="flex" flexDirection="column">
            <SearchCatalogItems
                catalogId={catalogId}
                moveTo={PropertyCatalogItemsMoveTo.ORDER}
                expandable
            />

            {!isActive && (
                <Box
                    mt="16px"
                    display="flex"
                    style={{ flex: 1 }}
                    borderRadius="12px 0 0 12px"
                >
                    <PropertyCatalog
                        isEditing
                        moveTo={PropertyCatalogItemsMoveTo.ORDER}
                        actionButtons={
                            <CreateOrderWithItemsButton
                                isLoadingUpdateOrder={isLoadingUpdateOrder}
                                orderType={orderType}
                            />
                        }
                    />
                </Box>
            )}
        </Box>
    );
};
